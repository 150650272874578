import React from "react";
import Datetime from "react-datetime";
import MaterialTable from "material-table";
import { CsvBuilder } from "filefy";
import toast from "react-hot-toast";
import * as moment from "moment-timezone";
import "moment/locale/ja";

import { API, graphqlOperation } from "aws-amplify";
import { updateWorkControl, deleteWorkControl } from "src/graphql/mutations";

import tablei18n from "src/tablei18n.js";

import Badge from "src/components/Badge";

/**
 * WorkControlTable
 *
 * @author Thais Watanabe
 */
const WorkControlTable = (props) => {
  const { workControls, isLoading, dateStart, dateEnd, fetchWorkControls } =
    props;

  /**
   * handleDateTimePicker
   * @param {*} e
   * @param {*} props
   */
  const handleDateTimePicker = (e, props) => {
    if (e.length === 0) {
      props.onChange(null);
    } else {
      try {
        props.onChange(e.tz("Asia/Tokyo"));
      } catch (e) {}
    }
  };

  /**
   * showNotification
   * @param {*} type
   * @param {*} message
   */
  function showNotification(type, message) {
    if (type === "success") {
      toast.success(message, {
        duration: 6000,
      });
    } else {
      toast.error(message, {
        duration: 6000,
      });
    }
  }

  return (
    <MaterialTable
      isLoading={isLoading}
      options={{
        exportButton: true,
        exportAllData: true,
        pageSize: 20,
        pageSizeOptions: [20, 40, 60, 100],
        doubleHorizontalScroll: true,
        exportCsv: (columnList, initialData) => {
          const columns = columnList.filter((columnDef) => {
            return (
              !columnDef.hidden && columnDef.field && columnDef.export !== false
            );
          });

          const data = initialData.map((rowData) =>
            columns.map((columnDef) => {
              return columnDef.render
                ? columnDef.render(rowData)
                : rowData[columnDef.field];
            })
          );

          const builder = new CsvBuilder(
            moment(dateStart).format("YYYY-MM-DD") ===
            moment(dateEnd).format("YYYY-MM-DD")
              ? "作業管理_" + moment(dateStart).format("YYYY-MM-DD") + ".csv"
              : "作業管理_" +
                moment(dateStart).format("YYYY-MM-DD") +
                "_" +
                moment(dateEnd).format("YYYY-MM-DD") +
                ".csv"
          );
          builder
            .setDelimeter(",")
            .setColumns(columns.map((columnDef) => columnDef.title))
            .addRows(data)
            .exportFile();
        },
        rowStyle: {
          color: "#4B5563",
          fontSize: "14px",
        },
        headerStyle: {
          backgroundColor: "#E5E7EB",
          color: "#1F2937",
        },
      }}
      columns={[
        {
          title: "日付",
          field: "date",
          editable: "never",
          render: (rowData) =>
            moment(rowData.date).tz("Asia/Tokyo").format("MM/DD"),
        },
        {
          title: "ステータス",
          field: "status",
          editable: "never",
          export: false,
          render: (rowData) =>
            rowData.endTime != null ? (
              <Badge color="gray">作業終了</Badge>
            ) : (
              <Badge color="green">作業中</Badge>
            ),
        },
        {
          title: "発電所",
          field: "windFarm",
          cellStyle: { width: "9%" },
        },
        { title: "号機", field: "tower" },
        {
          title: "入構時間",
          field: "startTime",
          render: (rowData) =>
            moment(rowData.startTime).tz("Asia/Tokyo").format("LT"),
          editComponent: (props) => (
            <Datetime
              id="startTime"
              name="startTime"
              inputProps={{ placeholder: "入構時間" }}
              locale="ja"
              dateFormat={false}
              value={
                moment(props.value).isValid()
                  ? moment(props.value).tz("Asia/Tokyo")
                  : ""
              }
              onChange={(e) => handleDateTimePicker(e, props)}
              className="mt-1 border border-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500"
            />
          ),
        },
        {
          title: "出構予定時間",
          field: "endTimePrev",
          render: (rowData) =>
            moment(rowData.endTimePrev).tz("Asia/Tokyo").format("LT"),
          editComponent: (props) => (
            <Datetime
              id="endTimePrev"
              name="endTimePrev"
              inputProps={{ placeholder: "出構予定時間" }}
              locale="ja"
              dateFormat={false}
              value={
                moment(props.value).isValid()
                  ? moment(props.value).tz("Asia/Tokyo")
                  : ""
              }
              onChange={(e) => handleDateTimePicker(e, props)}
              className="mt-1 border border-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500"
            />
          ),
        },
        { title: "作業員", field: "technicians" },
        { title: "作業名", field: "workName" },
        { title: "安全重点項目", field: "safetyPriority" },
        {
          title: "出構時間",
          field: "endTime",
          render: (rowData) =>
            rowData.endTime != null
              ? moment(rowData.endTime).tz("Asia/Tokyo").format("LT")
              : "",
          editComponent: (props) => (
            <Datetime
              id="endTime"
              name="endTime"
              inputProps={{ placeholder: "出構時間" }}
              locale="ja"
              dateFormat={false}
              value={moment(props.value).isValid() ? moment(props.value) : ""}
              onChange={(e) => handleDateTimePicker(e, props)}
              className="mt-1 border border-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500"
            />
          ),
        },
        {
          title: "風車内滞在時間",
          field: "workTime",
          editable: "never",
          render: (rowData) =>
            rowData.endTime != null
              ? moment
                  .utc(
                    moment
                      .duration(
                        moment(rowData.endTime)
                          .seconds(0)
                          .milliseconds(0)
                          .diff(
                            moment(rowData.startTime).seconds(0).milliseconds(0)
                          )
                      )
                      .asMilliseconds()
                  )
                  .format("HH:mm")
              : "",
        },
        {
          title: "メーカー",
          field: "maker",
          editable: "never",
        },
      ]}
      data={workControls}
      actions={[
        {
          icon: "refresh",
          tooltip: "更新する",
          isFreeAction: true,
          onClick: () => fetchWorkControls(),
        },
      ]}
      title={
        moment(dateStart).format("YYYY-MM-DD") ===
        moment(dateEnd).format("YYYY-MM-DD")
          ? moment(dateStart).format("YYYY-MM-DD")
          : moment(dateStart).format("YYYY-MM-DD") +
            "~" +
            moment(dateEnd).format("YYYY-MM-DD")
      }
      editable={{
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            delete newData.__typename;
            delete newData.createdAt;
            delete newData.updatedAt;
            newData.date = moment(dateStart).format("YYYY-MM-DD");
            API.graphql(
              graphqlOperation(updateWorkControl, {
                input: newData,
              })
            )
              .then((result) => {
                showNotification("success", "保存しました！");
              })
              .catch((err) => {
                showNotification("error", "エラーが発生しました！");
                console.log(err);
              });
            resolve();
          }),
        onRowDelete: (oldData) =>
          new Promise((resolve, reject) => {
            const input = { id: oldData.id };
            API.graphql(
              graphqlOperation(deleteWorkControl, {
                input: input,
              })
            )
              .then((result) => {
                showNotification("success", "保存しました！");
              })
              .catch((err) => {
                showNotification("error", "エラーが発生しました！");
                console.log(err);
              });
            resolve();
          }),
      }}
      localization={tablei18n}
    />
  );
};

export default WorkControlTable;
