/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listAlarmTamanouraByIds = /* GraphQL */ `
  query ListAlarmTamanouraByIds(
    $ids: [String]
    $limit: Int
    $nextToken: String
  ) {
    listAlarmTamanouraByIds(ids: $ids, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        reset
        repeatingCount
        detectionSensor
        cause
        detectionConditions
        interaction
        order
      }
      nextToken
    }
  }
`;
export const listAlarmReimeiByIds = /* GraphQL */ `
  query ListAlarmReimeiByIds($ids: [String], $limit: Int, $nextToken: String) {
    listAlarmReimeiByIds(ids: $ids, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        level
        falha
        reset
        description
        interaction1
        interaction2
        order
      }
      nextToken
    }
  }
`;
export const listAlarmAtsumiByIds = /* GraphQL */ `
  query ListAlarmAtsumiByIds($ids: [String], $limit: Int, $nextToken: String) {
    listAlarmAtsumiByIds(ids: $ids, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        state
        acknowledge
        cause
        interaction
        order
      }
      nextToken
    }
  }
`;
export const listAlarmNomozakiByIds = /* GraphQL */ `
  query ListAlarmNomozakiByIds(
    $ids: [String]
    $limit: Int
    $nextToken: String
  ) {
    listAlarmNomozakiByIds(ids: $ids, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        contents
        stopFrequency
        stopTime
        stopInterruption
        errorHeavy
        errorLight
        errorResetLocal
        errorResetRemote
        errorResetAuto
        alarmWarning
        alarmResetLocal
        alarmResetRemote
        alarmResetAuto
        interaction
        order
      }
      nextToken
    }
  }
`;
export const listAlarmTomieByIds = /* GraphQL */ `
  query ListAlarmTomieByIds($ids: [String], $limit: Int, $nextToken: String) {
    listAlarmTomieByIds(ids: $ids, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        resetLocal
        resetRemote
        resetAuto
        interaction
        order
      }
      nextToken
    }
  }
`;
export const listAlarmSakiyamaByIds = /* GraphQL */ `
  query ListAlarmSakiyamaByIds(
    $ids: [String]
    $limit: Int
    $nextToken: String
  ) {
    listAlarmSakiyamaByIds(ids: $ids, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        level
        falha
        reset
        description
        coping
        interaction
        order
      }
      nextToken
    }
  }
`;
export const listAlarmAinoByIds = /* GraphQL */ `
  query ListAlarmAinoByIds($ids: [String], $limit: Int, $nextToken: String) {
    listAlarmAinoByIds(ids: $ids, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        procedure
        alert
        reset
        interaction
        order
      }
      nextToken
    }
  }
`;
export const getMonitoringReport = /* GraphQL */ `
  query GetMonitoringReport($id: ID!) {
    getMonitoringReport(id: $id) {
      id
      windFarm
      tower
      alarmId
      alarmName
      alarmType
      alarmTime
      restoreTime
      situation
      maxWindSpeed
      avgWindSpeed
      windDirection
      responsible
      obs
      createdAt
      updatedAt
    }
  }
`;
export const listMonitoringReports = /* GraphQL */ `
  query ListMonitoringReports(
    $filter: ModelMonitoringReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMonitoringReports(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        windFarm
        tower
        alarmId
        alarmName
        alarmType
        alarmTime
        restoreTime
        situation
        maxWindSpeed
        avgWindSpeed
        windDirection
        responsible
        obs
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAlarmTamanoura = /* GraphQL */ `
  query GetAlarmTamanoura($id: ID!) {
    getAlarmTamanoura(id: $id) {
      id
      name
      reset
      repeatingCount
      detectionSensor
      cause
      detectionConditions
      interaction
      order
      createdAt
      updatedAt
    }
  }
`;
export const listAlarmTamanouras = /* GraphQL */ `
  query ListAlarmTamanouras(
    $filter: ModelAlarmTamanouraFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAlarmTamanouras(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        reset
        repeatingCount
        detectionSensor
        cause
        detectionConditions
        interaction
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAlarmReimei = /* GraphQL */ `
  query GetAlarmReimei($id: ID!) {
    getAlarmReimei(id: $id) {
      id
      name
      level
      falha
      reset
      description
      interaction1
      interaction2
      order
      createdAt
      updatedAt
    }
  }
`;
export const listAlarmReimeis = /* GraphQL */ `
  query ListAlarmReimeis(
    $filter: ModelAlarmReimeiFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAlarmReimeis(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        level
        falha
        reset
        description
        interaction1
        interaction2
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAlarmAtsumi = /* GraphQL */ `
  query GetAlarmAtsumi($id: ID!) {
    getAlarmAtsumi(id: $id) {
      id
      name
      state
      acknowledge
      cause
      interaction
      order
      createdAt
      updatedAt
    }
  }
`;
export const listAlarmAtsumis = /* GraphQL */ `
  query ListAlarmAtsumis(
    $filter: ModelAlarmAtsumiFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAlarmAtsumis(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        state
        acknowledge
        cause
        interaction
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAlarmNomozaki = /* GraphQL */ `
  query GetAlarmNomozaki($id: ID!) {
    getAlarmNomozaki(id: $id) {
      id
      name
      contents
      stopFrequency
      stopTime
      stopInterruption
      errorHeavy
      errorLight
      errorResetLocal
      errorResetRemote
      errorResetAuto
      alarmWarning
      alarmResetLocal
      alarmResetRemote
      alarmResetAuto
      interaction
      order
      createdAt
      updatedAt
    }
  }
`;
export const listAlarmNomozakis = /* GraphQL */ `
  query ListAlarmNomozakis(
    $filter: ModelAlarmNomozakiFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAlarmNomozakis(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        contents
        stopFrequency
        stopTime
        stopInterruption
        errorHeavy
        errorLight
        errorResetLocal
        errorResetRemote
        errorResetAuto
        alarmWarning
        alarmResetLocal
        alarmResetRemote
        alarmResetAuto
        interaction
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAlarmTomie = /* GraphQL */ `
  query GetAlarmTomie($id: ID!) {
    getAlarmTomie(id: $id) {
      id
      name
      resetLocal
      resetRemote
      resetAuto
      interaction
      order
      createdAt
      updatedAt
    }
  }
`;
export const listAlarmTomies = /* GraphQL */ `
  query ListAlarmTomies(
    $filter: ModelAlarmTomieFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAlarmTomies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        resetLocal
        resetRemote
        resetAuto
        interaction
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAlarmSakiyama = /* GraphQL */ `
  query GetAlarmSakiyama($id: ID!) {
    getAlarmSakiyama(id: $id) {
      id
      name
      level
      falha
      reset
      description
      coping
      interaction
      order
      createdAt
      updatedAt
    }
  }
`;
export const listAlarmSakiyamas = /* GraphQL */ `
  query ListAlarmSakiyamas(
    $filter: ModelAlarmSakiyamaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAlarmSakiyamas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        level
        falha
        reset
        description
        coping
        interaction
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAlarmAino = /* GraphQL */ `
  query GetAlarmAino($id: ID!) {
    getAlarmAino(id: $id) {
      id
      name
      description
      procedure
      alert
      reset
      interaction
      order
      createdAt
      updatedAt
    }
  }
`;
export const listAlarmAinos = /* GraphQL */ `
  query ListAlarmAinos(
    $filter: ModelAlarmAinoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAlarmAinos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        procedure
        alert
        reset
        interaction
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getWorkControl = /* GraphQL */ `
  query GetWorkControl($id: ID!) {
    getWorkControl(id: $id) {
      id
      date
      windFarm
      tower
      startTime
      endTimePrev
      technicians
      workName
      safetyPriority
      endTime
      author
      maker
      createdAt
      updatedAt
    }
  }
`;
export const listWorkControls = /* GraphQL */ `
  query ListWorkControls(
    $filter: ModelWorkControlFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorkControls(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        date
        windFarm
        tower
        startTime
        endTimePrev
        technicians
        workName
        safetyPriority
        endTime
        author
        maker
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getWindFarmInformation = /* GraphQL */ `
  query GetWindFarmInformation($id: ID!) {
    getWindFarmInformation(id: $id) {
      id
      prefecture
      name
      reading
      company
      fullName
      whereabouts
      manufacturer
      radix
      totalOutput
      longitude
      latitude
      latlong
      createdAt
      updatedAt
    }
  }
`;
export const listWindFarmInformations = /* GraphQL */ `
  query ListWindFarmInformations(
    $filter: ModelWindFarmInformationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWindFarmInformations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        prefecture
        name
        reading
        company
        fullName
        whereabouts
        manufacturer
        radix
        totalOutput
        longitude
        latitude
        latlong
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getWindTurbineDetails = /* GraphQL */ `
  query GetWindTurbineDetails($id: ID!) {
    getWindTurbineDetails(id: $id) {
      id
      date
      status
      windFarm
      tower
      description
      author
      obs
      createdAt
      updatedAt
    }
  }
`;
export const listWindTurbineDetailss = /* GraphQL */ `
  query ListWindTurbineDetailss(
    $filter: ModelWindTurbineDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWindTurbineDetailss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        status
        windFarm
        tower
        description
        author
        obs
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getWindTurbineNotePad = /* GraphQL */ `
  query GetWindTurbineNotePad($id: ID!) {
    getWindTurbineNotePad(id: $id) {
      id
      date
      windFarm
      tower
      author
      obs
      createdAt
      updatedAt
    }
  }
`;
export const listWindTurbineNotePads = /* GraphQL */ `
  query ListWindTurbineNotePads(
    $filter: ModelWindTurbineNotePadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWindTurbineNotePads(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        windFarm
        tower
        author
        obs
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
