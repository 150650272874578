import Dashboard from "src/views/dashboard/Dashboard.jsx";
import AlarmList from "src/views/alarms/AlarmList.jsx";
// import MonitoringReport from "src/views/MonitoringReport.jsx"
import WorkControl from "src/views/work-control/WorkControl.jsx"
// import WindFarmList from "src/views/WindFarmList.jsx"
// import WindTurbineStatus from "src/views/WindTurbineStatus.jsx"
// import WindTurbineDetails from "src/views/WindTurbineDetails.jsx"
// import WindTurbineDailyDetails from "src/views/WindTurbineDailyDetails.jsx"

import Map from "src/views/map/Map.jsx"

var routes = [
  {
    exact: true,
    path: "/",
    name: "ホームページ",
    icon: "fas fa-home",
    component: Dashboard,
    visible: true
  },
  {
    exact: false,
    path: "/alarm-list",
    name: "アラームリスト",
    icon: "tim-icons icon-bullet-list-67",
    component: AlarmList,
    visible: true
  },
  // {
  //   exact: false,
  //   path: "/monitoring-report",
  //   name: "監視日報",
  //   icon: "tim-icons icon-notes",
  //   component: MonitoringReport,
  //   visible: true
  // },
  // {
  //   exact: false,
  //   path: "/turbine-status",
  //   name: "監視風車状況",
  //   icon: "tim-icons icon-sound-wave",
  //   component: WindTurbineStatus,
  //   visible: true
  // },
  // {
  //   exact: false,
  //   path: "/turbine-detail/:id",
  //   name: "監視風車詳細",
  //   icon: "far fa-map",
  //   component: WindTurbineDetails,
  //   visible: false
  // },
  // {
  //   exact: false,
  //   path: "/turbine-status-daily",
  //   name: "監視風車詳細一覧",
  //   icon: "far fa-map",
  //   component: WindTurbineDailyDetails,
  //   visible: false
  // },
  {
    exact: false,
    path: "/work-control",
    name: "作業管理",
    icon: "fas fa-briefcase",
    component: WorkControl,
    visible: true
  },
  {
    exact: false,
    path: "/live-map",
    name: "作業ライブマップ",
    icon: "far fa-map",
    component: Map,
    visible: true
  },
  // {
  //   exact: false,
  //   path: "/windfarm-list",
  //   name: "発電所情報",
  //   icon: "fas fa-database",
  //   component: WindFarmList,
  //   visible: true
  // }
];
export default routes;
