export const windFarms = [
  { id: 0, name: "選択してください" },
  { id: 1, name: "玉之浦" },
  { id: 2, name: "れいめい" },
  { id: 3, name: "渥美" },
  { id: 4, name: "野母崎" },
  { id: 5, name: "富江" },
  { id: 6, name: "崎山" },
  { id: 7, name: "愛野" },
];
