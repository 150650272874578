import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import awsconfig from "./aws-exports";
import Amplify from "aws-amplify";
import { Auth } from "@aws-amplify/auth";
import { I18n } from "aws-amplify";
import ja from "./i18n-ja";

import App from "src/App";

// import "assets/css/black-dashboard-react.css";
// import "assets/css/nucleo-icons.css";
import "react-datetime/css/react-datetime.css";

import './index.css';
import "@aws-amplify/ui/dist/style.css";

Amplify.configure(awsconfig);
Auth.configure(awsconfig);

I18n.putVocabulariesForLanguage("ja", ja);

const authScreenLabels = {
  ja: {
    "Username cannot be empty": "ユーザー名を入力してください",
    "User does not exist": "ユーザー名が存在しません",
    "Incorrect username or password": "ユーザー名とパスワードを確認できません",
  },
};

I18n.setLanguage("ja");
I18n.putVocabularies(authScreenLabels);

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);
