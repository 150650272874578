/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:ef074a3b-cb57-4fad-a318-ae6bb49ba396",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_noU8wqI43",
    "aws_user_pools_web_client_id": "4ha8q0h671q0fq5dh5kfnm8511",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://lata6iqvwve27glgmnhygpeotu.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_content_delivery_bucket": "jyohokanriappreact-20200928221550-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "ap-northeast-1",
    "aws_content_delivery_url": "https://d2fxclot9cgvyw.cloudfront.net"
};


export default awsmobile;
