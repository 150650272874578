/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateMonitoringReport = /* GraphQL */ `
  subscription OnCreateMonitoringReport {
    onCreateMonitoringReport {
      id
      windFarm
      tower
      alarmId
      alarmName
      alarmType
      alarmTime
      restoreTime
      situation
      maxWindSpeed
      avgWindSpeed
      windDirection
      responsible
      obs
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMonitoringReport = /* GraphQL */ `
  subscription OnUpdateMonitoringReport {
    onUpdateMonitoringReport {
      id
      windFarm
      tower
      alarmId
      alarmName
      alarmType
      alarmTime
      restoreTime
      situation
      maxWindSpeed
      avgWindSpeed
      windDirection
      responsible
      obs
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMonitoringReport = /* GraphQL */ `
  subscription OnDeleteMonitoringReport {
    onDeleteMonitoringReport {
      id
      windFarm
      tower
      alarmId
      alarmName
      alarmType
      alarmTime
      restoreTime
      situation
      maxWindSpeed
      avgWindSpeed
      windDirection
      responsible
      obs
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAlarmTamanoura = /* GraphQL */ `
  subscription OnCreateAlarmTamanoura {
    onCreateAlarmTamanoura {
      id
      name
      reset
      repeatingCount
      detectionSensor
      cause
      detectionConditions
      interaction
      order
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAlarmTamanoura = /* GraphQL */ `
  subscription OnUpdateAlarmTamanoura {
    onUpdateAlarmTamanoura {
      id
      name
      reset
      repeatingCount
      detectionSensor
      cause
      detectionConditions
      interaction
      order
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAlarmTamanoura = /* GraphQL */ `
  subscription OnDeleteAlarmTamanoura {
    onDeleteAlarmTamanoura {
      id
      name
      reset
      repeatingCount
      detectionSensor
      cause
      detectionConditions
      interaction
      order
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAlarmReimei = /* GraphQL */ `
  subscription OnCreateAlarmReimei {
    onCreateAlarmReimei {
      id
      name
      level
      falha
      reset
      description
      interaction1
      interaction2
      order
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAlarmReimei = /* GraphQL */ `
  subscription OnUpdateAlarmReimei {
    onUpdateAlarmReimei {
      id
      name
      level
      falha
      reset
      description
      interaction1
      interaction2
      order
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAlarmReimei = /* GraphQL */ `
  subscription OnDeleteAlarmReimei {
    onDeleteAlarmReimei {
      id
      name
      level
      falha
      reset
      description
      interaction1
      interaction2
      order
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAlarmAtsumi = /* GraphQL */ `
  subscription OnCreateAlarmAtsumi {
    onCreateAlarmAtsumi {
      id
      name
      state
      acknowledge
      cause
      interaction
      order
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAlarmAtsumi = /* GraphQL */ `
  subscription OnUpdateAlarmAtsumi {
    onUpdateAlarmAtsumi {
      id
      name
      state
      acknowledge
      cause
      interaction
      order
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAlarmAtsumi = /* GraphQL */ `
  subscription OnDeleteAlarmAtsumi {
    onDeleteAlarmAtsumi {
      id
      name
      state
      acknowledge
      cause
      interaction
      order
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAlarmNomozaki = /* GraphQL */ `
  subscription OnCreateAlarmNomozaki {
    onCreateAlarmNomozaki {
      id
      name
      contents
      stopFrequency
      stopTime
      stopInterruption
      errorHeavy
      errorLight
      errorResetLocal
      errorResetRemote
      errorResetAuto
      alarmWarning
      alarmResetLocal
      alarmResetRemote
      alarmResetAuto
      interaction
      order
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAlarmNomozaki = /* GraphQL */ `
  subscription OnUpdateAlarmNomozaki {
    onUpdateAlarmNomozaki {
      id
      name
      contents
      stopFrequency
      stopTime
      stopInterruption
      errorHeavy
      errorLight
      errorResetLocal
      errorResetRemote
      errorResetAuto
      alarmWarning
      alarmResetLocal
      alarmResetRemote
      alarmResetAuto
      interaction
      order
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAlarmNomozaki = /* GraphQL */ `
  subscription OnDeleteAlarmNomozaki {
    onDeleteAlarmNomozaki {
      id
      name
      contents
      stopFrequency
      stopTime
      stopInterruption
      errorHeavy
      errorLight
      errorResetLocal
      errorResetRemote
      errorResetAuto
      alarmWarning
      alarmResetLocal
      alarmResetRemote
      alarmResetAuto
      interaction
      order
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAlarmTomie = /* GraphQL */ `
  subscription OnCreateAlarmTomie {
    onCreateAlarmTomie {
      id
      name
      resetLocal
      resetRemote
      resetAuto
      interaction
      order
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAlarmTomie = /* GraphQL */ `
  subscription OnUpdateAlarmTomie {
    onUpdateAlarmTomie {
      id
      name
      resetLocal
      resetRemote
      resetAuto
      interaction
      order
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAlarmTomie = /* GraphQL */ `
  subscription OnDeleteAlarmTomie {
    onDeleteAlarmTomie {
      id
      name
      resetLocal
      resetRemote
      resetAuto
      interaction
      order
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAlarmSakiyama = /* GraphQL */ `
  subscription OnCreateAlarmSakiyama {
    onCreateAlarmSakiyama {
      id
      name
      level
      falha
      reset
      description
      coping
      interaction
      order
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAlarmSakiyama = /* GraphQL */ `
  subscription OnUpdateAlarmSakiyama {
    onUpdateAlarmSakiyama {
      id
      name
      level
      falha
      reset
      description
      coping
      interaction
      order
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAlarmSakiyama = /* GraphQL */ `
  subscription OnDeleteAlarmSakiyama {
    onDeleteAlarmSakiyama {
      id
      name
      level
      falha
      reset
      description
      coping
      interaction
      order
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAlarmAino = /* GraphQL */ `
  subscription OnCreateAlarmAino {
    onCreateAlarmAino {
      id
      name
      description
      procedure
      alert
      reset
      interaction
      order
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAlarmAino = /* GraphQL */ `
  subscription OnUpdateAlarmAino {
    onUpdateAlarmAino {
      id
      name
      description
      procedure
      alert
      reset
      interaction
      order
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAlarmAino = /* GraphQL */ `
  subscription OnDeleteAlarmAino {
    onDeleteAlarmAino {
      id
      name
      description
      procedure
      alert
      reset
      interaction
      order
      createdAt
      updatedAt
    }
  }
`;
export const onCreateWorkControl = /* GraphQL */ `
  subscription OnCreateWorkControl {
    onCreateWorkControl {
      id
      date
      windFarm
      tower
      startTime
      endTimePrev
      technicians
      workName
      safetyPriority
      endTime
      author
      maker
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateWorkControl = /* GraphQL */ `
  subscription OnUpdateWorkControl {
    onUpdateWorkControl {
      id
      date
      windFarm
      tower
      startTime
      endTimePrev
      technicians
      workName
      safetyPriority
      endTime
      author
      maker
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteWorkControl = /* GraphQL */ `
  subscription OnDeleteWorkControl {
    onDeleteWorkControl {
      id
      date
      windFarm
      tower
      startTime
      endTimePrev
      technicians
      workName
      safetyPriority
      endTime
      author
      maker
      createdAt
      updatedAt
    }
  }
`;
export const onCreateWindFarmInformation = /* GraphQL */ `
  subscription OnCreateWindFarmInformation {
    onCreateWindFarmInformation {
      id
      prefecture
      name
      reading
      company
      fullName
      whereabouts
      manufacturer
      radix
      totalOutput
      longitude
      latitude
      latlong
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateWindFarmInformation = /* GraphQL */ `
  subscription OnUpdateWindFarmInformation {
    onUpdateWindFarmInformation {
      id
      prefecture
      name
      reading
      company
      fullName
      whereabouts
      manufacturer
      radix
      totalOutput
      longitude
      latitude
      latlong
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteWindFarmInformation = /* GraphQL */ `
  subscription OnDeleteWindFarmInformation {
    onDeleteWindFarmInformation {
      id
      prefecture
      name
      reading
      company
      fullName
      whereabouts
      manufacturer
      radix
      totalOutput
      longitude
      latitude
      latlong
      createdAt
      updatedAt
    }
  }
`;
export const onCreateWindTurbineDetails = /* GraphQL */ `
  subscription OnCreateWindTurbineDetails {
    onCreateWindTurbineDetails {
      id
      date
      status
      windFarm
      tower
      description
      author
      obs
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateWindTurbineDetails = /* GraphQL */ `
  subscription OnUpdateWindTurbineDetails {
    onUpdateWindTurbineDetails {
      id
      date
      status
      windFarm
      tower
      description
      author
      obs
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteWindTurbineDetails = /* GraphQL */ `
  subscription OnDeleteWindTurbineDetails {
    onDeleteWindTurbineDetails {
      id
      date
      status
      windFarm
      tower
      description
      author
      obs
      createdAt
      updatedAt
    }
  }
`;
export const onCreateWindTurbineNotePad = /* GraphQL */ `
  subscription OnCreateWindTurbineNotePad {
    onCreateWindTurbineNotePad {
      id
      date
      windFarm
      tower
      author
      obs
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateWindTurbineNotePad = /* GraphQL */ `
  subscription OnUpdateWindTurbineNotePad {
    onUpdateWindTurbineNotePad {
      id
      date
      windFarm
      tower
      author
      obs
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteWindTurbineNotePad = /* GraphQL */ `
  subscription OnDeleteWindTurbineNotePad {
    onDeleteWindTurbineNotePad {
      id
      date
      windFarm
      tower
      author
      obs
      createdAt
      updatedAt
    }
  }
`;
