import React, { Fragment, useEffect, useState, useRef } from "react";
import { API, Auth } from "aws-amplify";
import { graphqlOperation } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { Dialog, Transition } from "@headlessui/react";
import { PlusIcon } from "@heroicons/react/outline";
import Datetime from "react-datetime";
import Select from "react-select";
import * as moment from "moment-timezone";
import toast from "react-hot-toast";

import { createWorkControl } from "src/graphql/mutations";
import {
  getWindFarmInformation,
  listWindFarmInformations,
} from "src/graphql/queries";
import towers from "src/variables/WindTurbines";
import InputError from "src/components/Form/InputError";

import "moment/locale/ja";

const zone = "Asia/Tokyo";

const initialState = {
  date: moment().tz(zone).format("YYYY-MM-DD"),
  windFarm: "",
  tower: [],
  startTime: moment().tz(zone),
  endTimePrev: moment().tz(zone),
  technicians: "",
  workName: "",
  safetyPriority: "",
  author: "",
};

const initialErrorState = {
  windFarm: "",
  tower: "",
  startTime: "",
  endTimePrev: "",
  technicians: "",
  workName: "",
  safetyPriority: "",
};

/**
 * AddWorkControlData
 *
 * @author Thais Watanabe
 */
const AddWorkControlData = () => {
  const [formState, setFormState] = useState(initialState);
  const [errors, setErrors] = useState(initialErrorState);
  const [windFarms, setWindFarms] = useState([]);
  const [loggedUser, setLoggedUser] = useState("");
  const [isModalOpen, setShowModal] = useState(false);

  const cancelButtonRef = useRef();

  /**
   * Get current user
   */
  useEffect(() => {
    Auth.currentUserInfo()
      .then((result) => {
        setLoggedUser(result.username);
      })
      .catch((error) => console.log("error: ", error));
  }, []);

  /**
   * useEffect
   */
  useEffect(() => {
    fetchWindFarms();
  }, []);

  /**
   * fetchWindFarms
   */
  async function fetchWindFarms() {
    try {
      const windFarmsData = await API.graphql(
        graphqlOperation(listWindFarmInformations)
      );
      const windFarms = windFarmsData.data.listWindFarmInformations.items;

      var newInfos = [];
      windFarms.forEach((info, key) => {
        if (info) {
          newInfos.push({
            key: key,
            id: info.id,
            value: info.name,
            label: info.name,
          });
        }
      });
      setWindFarms(newInfos);
    } catch (err) {
      console.log(err);
      toast.error("エラーが発生しました！", {
        duration: 6000,
      });
    }
  }

  /**
   * Reset form state
   */
  function resetState() {
    setFormState({
      date: moment().tz(zone).format("YYYY-MM-DD"),
      windFarm: "",
      tower: [],
      startTime: moment().tz(zone),
      endTimePrev: moment().tz(zone),
      technicians: "",
      workName: "",
      safetyPriority: "",
      author: "",
    });
    setErrors(initialErrorState);
  }

  /**
   * Set input
   * @param {*} key
   * @param {*} value
   */
  function setInput(key, value) {
    setFormState({ ...formState, [key]: value });
  }

  /**
   * handleInputMultipleChange
   * @param {*} event
   */
  function handleInputMultipleChange(event) {
    let opts = [],
      opt;

    for (let i = 0, len = event.target.options.length; i < len; i++) {
      opt = event.target.options[i];

      if (opt.selected) {
        opts.push(opt.value);
      }
    }
    setInput("tower", opts);
  }

  /**
   * validateFields
   */
  function validateFields() {
    var newErrors = {};
    var hasErrors = false;
    if (!formState.windFarm.value) {
      newErrors.windFarm = "選択してください";
      hasErrors = true;
    } else {
      newErrors.windFarm = "";
    }
    if (formState.tower.length === 0) {
      newErrors.tower = "選択してください";
      hasErrors = true;
    } else {
      newErrors.tower = "";
    }
    if (formState.technicians.length === 0) {
      newErrors.technicians = "入力してください";
      hasErrors = true;
    } else {
      newErrors.technicians = "";
    }
    if (formState.workName.length === 0) {
      newErrors.workName = "入力してください";
      hasErrors = true;
    } else {
      newErrors.workName = "";
    }
    if (formState.safetyPriority.length === 0) {
      newErrors.safetyPriority = "入力してください";
      hasErrors = true;
    } else {
      newErrors.safetyPriority = "";
    }
    setErrors(newErrors);
    return hasErrors;
  }

  /**
   * submit
   */
  function submit() {
    if (!validateFields()) {
      submitData();
    } else {
      toast.error("入力データを確認してください", {
        duration: 6000,
      });
    }
  }

  /**
   * submitData
   */
  async function submitData() {
    const newWorkControl = {
      id: uuidv4(),
      date: formState.date,
      windFarm: formState.windFarm.value,
      tower: formState.tower.join(),
      startTime: moment(formState.startTime).tz(zone).utc().format(),
      endTimePrev: moment(formState.endTimePrev).tz(zone).utc().format(),
      technicians: formState.technicians,
      workName: formState.workName,
      safetyPriority: formState.safetyPriority,
      author: loggedUser,
    };
    
    const windturbine = await API.graphql(
      graphqlOperation(getWindFarmInformation, { id: formState.windFarm.id })
    );
    newWorkControl.maker = windturbine.data.getWindFarmInformation.manufacturer;

    try {
      await API.graphql(
        graphqlOperation(createWorkControl, { input: newWorkControl })
      );
      // resetState();
      toast.success("保存しました！", {
        duration: 8000,
      });
    } catch (err) {
      toast.error("エラーが発生しました！", {
        duration: 6000,
      });
      console.error(err);
    }
    showModal();
  }

  /**
   * sortData
   * @param {*} data
   * @returns
   */
  function sortData(data) {
    return data.sort(function (a, b) {
      if (a.value < b.value) {
        return -1;
      }
      if (a.value > b.value) {
        return 1;
      }
      return 0;
    });
  }

  /**
   * showModal
   * @param {*} technician
   */
  function showModal() {
    resetState();
    setShowModal(!isModalOpen);
  }

  return (
    <div>
      <button
        type="button"
        className="w-full inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        onClick={showModal}
      >
        <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
        作業管理に追加
      </button>

      <Transition.Root show={isModalOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          open={isModalOpen}
          onClose={setShowModal}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full">
                <div className="bg-gray-50 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    作業追加
                  </h3>
                </div>
                <div className="bg-white sm:p-6 sm:pb-4">
                  <div className=" grid grid-cols-2 gap-y-6 gap-x-4 sm:grid-cols-2">
                    <div className="sm:col-span-1">
                      <label
                        htmlFor="date"
                        className="block text-sm font-medium text-gray-700"
                      >
                        日付
                      </label>
                      <input
                        type="text"
                        name="date"
                        id="date"
                        value={formState.date}
                        disabled={true}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm text-gray-400 sm:text-sm focus:ring-blue-500 focus:border-blue-500"
                      />
                    </div>

                    <div className="sm:col-span-1">
                      <label
                        htmlFor="responsible"
                        className="block text-sm font-medium text-gray-700"
                      >
                        担当者
                      </label>
                      <input
                        type="text"
                        name="responsible"
                        id="responsible"
                        value={loggedUser}
                        disabled={true}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm text-gray-400 sm:text-sm focus:ring-blue-500 focus:border-blue-500"
                      />
                    </div>

                    <div className="sm:col-span-2">
                      <label
                        htmlFor="responsible"
                        className="block text-sm font-medium text-gray-700"
                      >
                        風力発電所
                      </label>
                      <Select
                        value={formState.windFarm}
                        placeholder="風力発電所"
                        onChange={(value) => setInput("windFarm", value)}
                        required={true}
                        options={sortData(windFarms)}
                      />
                      {errors.windFarm && (
                        <InputError>{errors.windFarm}</InputError>
                      )}
                    </div>

                    <div className="sm:col-span-1">
                      <label
                        htmlFor="towers"
                        className="block text-sm font-medium text-gray-700"
                      >
                        号機
                      </label>
                      <select
                        value={formState.tower}
                        onChange={(event) => handleInputMultipleChange(event)}
                        className="h-5/6 form-multiselect mt-1 block w-full border border-gray-300 rounded-md shadow-sm text-gray-500 sm:text-sm focus:ring-blue-500 focus:border-blue-500"
                        multiple
                      >
                        {towers.map((value, key) => {
                          return (
                            <option key={key} value={value}>
                              {value}
                            </option>
                          );
                        })}
                        <option>全</option>
                        <option>サイト</option>
                      </select>
                      {errors.tower && <InputError>{errors.tower}</InputError>}
                    </div>

                    <div className="sm:grid-cols-1">
                      <label
                        htmlFor="startTime"
                        className="block text-sm font-medium text-gray-700"
                      >
                        入構時間
                      </label>
                      <Datetime
                        id="startTime"
                        name="startTime"
                        inputProps={{ placeholder: "入構時間" }}
                        locale="ja"
                        dateFormat={false}
                        value={formState.startTime}
                        onChange={(time) =>
                          setInput("startTime", time.tz(zone))
                        }
                        required={true}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm text-gray-500 sm:text-sm focus:ring-blue-500 focus:border-blue-500"
                      />
                      {errors.startTime && (
                        <InputError>{errors.startTime}</InputError>
                      )}

                      <label
                        htmlFor="endTimePrev"
                        className="mt-3 block text-sm font-medium text-gray-700"
                      >
                        出構予定時間
                      </label>
                      <Datetime
                        id="endTimePrev"
                        name="endTimePrev"
                        inputProps={{ placeholder: "出構予定時間" }}
                        locale="ja"
                        dateFormat={false}
                        value={formState.endTimePrev}
                        onChange={(time) =>
                          setInput("endTimePrev", time.tz(zone))
                        }
                        required={true}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm text-gray-500 sm:text-sm focus:ring-blue-500 focus:border-blue-500"
                      />
                      {errors.endTimePrev && (
                        <InputError>{errors.endTimePrev}</InputError>
                      )}
                    </div>

                    <div className="sm:col-span-2">
                      <label
                        htmlFor="technicians"
                        className="block text-sm font-medium text-gray-700"
                      >
                        作業員
                      </label>
                      <input
                        type="text"
                        name="technicians"
                        id="technicians"
                        value={formState.technicians}
                        onChange={(event) =>
                          setInput("technicians", event.target.value)
                        }
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm text-gray-500 sm:text-sm focus:ring-blue-500 focus:border-blue-500"
                      />
                      {errors.technicians && (
                        <InputError>{errors.technicians}</InputError>
                      )}
                    </div>

                    <div className="sm:col-span-2">
                      <label
                        htmlFor="workName"
                        className="block text-sm font-medium text-gray-700"
                      >
                        作業名
                      </label>
                      <input
                        type="text"
                        name="workName"
                        id="workName"
                        value={formState.workName}
                        onChange={(event) =>
                          setInput("workName", event.target.value)
                        }
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm text-gray-500 sm:text-sm focus:ring-blue-500 focus:border-blue-500"
                      />
                      {errors.workName && (
                        <InputError>{errors.workName}</InputError>
                      )}
                    </div>

                    <div className="sm:col-span-2">
                      <label
                        htmlFor="safetyPriority"
                        className="block text-sm font-medium text-gray-700"
                      >
                        安全重点項目
                      </label>
                      <input
                        type="text"
                        name="safetyPriority"
                        id="safetyPriority"
                        value={formState.safetyPriority}
                        onChange={(event) =>
                          setInput("safetyPriority", event.target.value)
                        }
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm text-gray-500 sm:text-sm focus:ring-blue-500 focus:border-blue-500"
                      />
                      {errors.safetyPriority && (
                        <InputError>{errors.safetyPriority}</InputError>
                      )}
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => submit()}
                  >
                    保存する
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setShowModal(false)}
                    ref={cancelButtonRef}
                  >
                    キャンセル
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default AddWorkControlData;
