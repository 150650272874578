import React from "react";

// reactstrap components
import {
  Row,
  Col,
  Input,
  FormGroup,
  Label,
  Button,
  Form,
  ModalBody,
  ModalFooter,
  Modal,
  ModalHeader,
} from "reactstrap";
import { PencilIcon } from "@heroicons/react/solid";

class EditAlarmData extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalDemo: false,
      windfarm: this.props.windfarm,
      alarm: this.props.alarm,
      // Form
      interaction:
        this.props.windfarm === "れいめい"
          ? this.props.alarm.interaction2 == null
            ? ""
            : this.props.alarm.interaction2
          : this.props.alarm.interaction == null
          ? ""
          : this.props.alarm.interaction,
      order: this.props.alarm.order == null ? "" : this.props.alarm.order,
      errors: {
        interaction: "",
        order: "",
      },
    };

    this.submit = this.submit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.toggleModalDemo = this.toggleModalDemo.bind(this);
  }

  toggleModalDemo() {
    this.setState({ modalDemo: !this.state.modalDemo });
  }

  handleInputChange = (event) => {
    event.preventDefault();

    const target = event.target;
    const value = target.value;
    const name = target.name;

    let errors = this.state.errors;

    this.setState({ errors, [name]: value });
  };

  showNotification(type, message) {
    // var options = {};
    // options = {
    //   place: "tr",
    //   message: (
    //     <div>
    //       {message}
    //     </div>
    //   ),
    //   type: type,
    //   icon: "tim-icons icon-bell-55",
    //   autoDismiss: 7
    // };
    // this.refs.notificationAlert.notificationAlert(options);
  }

  validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
    return valid;
  };

  async submit() {
    const { onUpdate } = this.props;

    if (this.validateForm(this.state.errors)) {
      var input;

      if (this.state.windfarm === "玉之浦") {
        input = {
          id: this.state.alarm.id,
          name: this.state.alarm.name,
          reset: this.state.alarm.reset,
          repeatingCount: this.state.alarm.repeatingCount,
          detectionSensor: this.state.alarm.detectionSensor,
          cause: this.state.alarm.cause,
          detectionConditions: this.state.alarm.detectionConditions,
        };
      } else if (this.state.windfarm === "れいめい") {
        input = {
          id: this.state.alarm.id,
          name: this.state.alarm.name,
          level: this.state.alarm.level,
          falha: this.state.alarm.falha,
          reset: this.state.alarm.reset,
          cause: this.state.alarm.cause,
          interaction1: this.state.alarm.interaction1,
        };
      } else if (this.state.windfarm === "渥美") {
        input = {
          id: this.state.alarm.id,
          name: this.state.alarm.name,
          state: this.state.alarm.state,
          acknowledge: this.state.alarm.acknowledge,
          cause: this.state.alarm.cause,
        };
      } else if (this.state.windfarm === "野母崎") {
        input = {
          id: this.state.alarm.id,
          name: this.state.alarm.name,
          contents: this.state.alarm.contents,
          stopTime: this.state.alarm.stopTime,
          stopInterruption: this.state.alarm.stopInterruption,
          errorHeavy: this.state.alarm.errorHeavy,
          errorLight: this.state.alarm.errorLight,
          errorResetLocal: this.state.alarm.errorResetLocal,
          errorResetRemote: this.state.alarm.errorResetRemote,
          errorResetAuto: this.state.alarm.errorResetAuto,
          alarmWarning: this.state.alarm.alarmWarning,
          alarmResetLocal: this.state.alarm.alarmResetLocal,
          alarmResetRemote: this.state.alarm.alarmResetRemote,
          alarmResetAuto: this.state.alarm.alarmResetAuto,
        };
      } else if (this.state.windfarm === "富江") {
        input = {
          id: this.state.alarm.id,
          name: this.state.alarm.name,
          resetLocal: this.state.alarm.resetLocal,
          resetRemote: this.state.alarm.resetRemote,
          resetAuto: this.state.alarm.resetAuto,
        };
      } else if (this.state.windfarm === "崎山") {
        input = {
          id: this.state.alarm.id,
          name: this.state.alarm.name,
          level: this.state.alarm.level,
          falha: this.state.alarm.falha,
          reset: this.state.alarm.reset,
          description: this.state.alarm.description,
          coping: this.state.alarm.coping,
        };
      } else if (this.state.windfarm === "愛野") {
        input = {
          id: this.state.alarm.id,
          name: this.state.alarm.name,
          description: this.state.alarm.description,
          procedure: this.state.alarm.procedure,
          alert: this.state.alarm.alert,
          reset: this.state.alarm.reset,
        };
      }

      if (this.state.windfarm === "れいめい") {
        if (this.state.interaction.length > 0) {
          input.interaction2 = this.state.interaction;
        } else {
          input.interaction2 = null;
        }
      } else {
        if (this.state.interaction.length > 0) {
          input.interaction = this.state.interaction;
        } else {
          input.interaction = null;
        }
      }

      if (this.state.order.length > 0) {
        input.order = this.state.order;
      } else {
        input.order = null;
      }

      try {
        await onUpdate({ input });
        this.showNotification("success", "保存しました！");
      } catch (err) {
        //this.showNotification("danger", "エラーが発生しました！");
        console.error(err);
      }
      this.toggleModalDemo();
    } else {
      console.error("Invalid Form");
    }
  }

  render() {
    return (
      <>
        <button
          type="button"
          className="bg-white rounded-full h-8 w-8 flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
        >
          <PencilIcon className="h-5 w-5" aria-hidden="true" />
          <span className="sr-only">Add description</span>
        </button>

        <Modal
          id="modal"
          isOpen={this.state.modalDemo}
          toggle={this.toggleModalDemo}
          size="lg"
        >
          <ModalHeader
            className="justify-content-center"
            toggle={this.toggleModalDemo}
          >
            アラーム修正
          </ModalHeader>
          <ModalBody>
            <Form autoComplete="off" noValidate>
              <Row>
                <Col className="pr-md-1" md="12">
                  <FormGroup>
                    <Label for="order" className="control-label">
                      リセット
                    </Label>
                    <Input
                      id="order"
                      name="order"
                      placeholder="リセット2"
                      type="textarea"
                      value={this.state.order}
                      onChange={this.handleInputChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className="pr-md-1" md="12">
                  <FormGroup>
                    <Label for="interaction" className="control-label">
                      対応
                    </Label>
                    <Input
                      id="interaction"
                      name="interaction"
                      placeholder="対応"
                      type="textarea"
                      value={this.state.interaction}
                      onChange={this.handleInputChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggleModalDemo}>
              キャンセルする
            </Button>
            <Button onClick={this.submit} color="primary">
              保存する
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default EditAlarmData;
