import React, { useState } from "react";

import { Connect } from "aws-amplify-react";
import { graphqlOperation } from "aws-amplify";
import {
  listAlarmTamanouraByIds,
  listAlarmReimeiByIds,
  listAlarmAtsumiByIds,
  listAlarmNomozakiByIds,
  listAlarmTomieByIds,
  listAlarmSakiyamaByIds,
  listAlarmAinoByIds,
} from "src/graphql/queries";
import * as subscriptions from "src/graphql/subscriptions";

import AddMonitoringData from "src/views/monitoring/AddMonitoringData";
import AlarmTamanouraView from "src/views/wind-farm-alarms/AlarmTamanouraView";
import AlarmReimeiView from "src/views/wind-farm-alarms/AlarmReimeiView";
import AlarmAtsumiView from "src/views/wind-farm-alarms/AlarmAtsumiView";
import AlarmNomozakiView from "src/views/wind-farm-alarms/AlarmNomozakiView";
import AlarmTomieView from "src/views/wind-farm-alarms/AlarmTomieView";
import AlarmSakiyamaView from "src/views/wind-farm-alarms/AlarmSakiyamaView";
import AlarmAinoView from "src/views/wind-farm-alarms/AlarmAinoView";

import Card from "src/components/Layout/Card";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon, SearchIcon } from "@heroicons/react/solid";

import { windFarms } from "src/utils/windFarms";

/**
 * classNames
 * @param  {...any} classes
 * @returns
 */
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const initialState = {
  // Form
  windFarm: windFarms[0],
  alarmIds: "",
};

/**
 * AlarmList
 *
 * @author Thais Watanabe
 */
const AlarmList = () => {
  const [formState, setFormState] = useState(initialState);
  const [selectedForm, setSelectedForm] = useState(initialState);

  /**
   * Set input
   * @param {*} key
   * @param {*} value
   */
  function setInput(key, value) {
    setFormState({ ...formState, [key]: value });
  }

  /**
   * submit
   */
  function submit() {
    setSelectedForm(formState);
  }

  // Views
  const LoadingView = () => <div>読み込み中...</div>;

  return (
    <div className="">
      <div className="flex-grow w-full lg:flex">
        {/* Left sidebar & main wrapper */}
        <div className="flex-1 min-w-0 xl:flex">
          <div className="border-b border-gray-200 xl:border-b-0 xl:flex-shrink-0 xl:w-64 xl:border-r xl:border-gray-200 ">
            <div className="h-full pl-4 pr-4 py-6 sm:pl-4 lg:pl-8 xl:pl-4">
              {/* Start left column area */}
              <div className="h-full relative" style={{ minHeight: "12rem" }}>
                <Card title="アラーム検索">
                  <div className="grid grid-cols-1 gap-y-1 sm:grid-cols-1 sm:gap-x-1">
                    <div className="sm:col-span-1">
                      <Listbox
                        value={formState.windFarm}
                        onChange={(event) => setInput("windFarm", event)}
                      >
                        {({ open }) => (
                          <>
                            <Listbox.Label className="block text-sm font-medium text-gray-700">
                              風力発電所
                            </Listbox.Label>
                            <div className="mt-1 relative">
                              <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                                <span className="block truncate">
                                  {formState.windFarm.name}
                                </span>
                                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                  <SelectorIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </span>
                              </Listbox.Button>

                              <Transition
                                show={open}
                                as={React.Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options
                                  static
                                  className="absolute mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                                >
                                  {windFarms.map((windFarm) => (
                                    <Listbox.Option
                                      key={windFarm.id}
                                      className={({ active }) =>
                                        classNames(
                                          active
                                            ? "text-white bg-blue-600"
                                            : "text-gray-900",
                                          "cursor-default select-none relative py-2 pl-8 pr-4"
                                        )
                                      }
                                      value={windFarm}
                                    >
                                      {({ selected, active }) => (
                                        <>
                                          <span
                                            className={classNames(
                                              selected
                                                ? "font-semibold"
                                                : "font-normal",
                                              "block truncate"
                                            )}
                                          >
                                            {windFarm.name}
                                          </span>

                                          {selected ? (
                                            <span
                                              className={classNames(
                                                active
                                                  ? "text-white"
                                                  : "text-blue-600",
                                                "absolute inset-y-0 left-0 flex items-center pl-1.5"
                                              )}
                                            >
                                              <CheckIcon
                                                className="h-5 w-5"
                                                aria-hidden="true"
                                              />
                                            </span>
                                          ) : null}
                                        </>
                                      )}
                                    </Listbox.Option>
                                  ))}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </>
                        )}
                      </Listbox>
                    </div>
                    <div className="sm:col-span-1">
                      <label
                        htmlFor="alarm_ids"
                        className="block text-sm font-medium text-gray-700"
                      >
                        アラームIDs
                      </label>
                      <input
                        type="text"
                        name="alarm_ids"
                        id="alarm_ids"
                        value={formState.alarmIds}
                        onChange={(event) =>
                          setInput("alarmIds", event.target.value)
                        }
                        placeholder="例：1,2,3"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500"
                      />
                    </div>
                    <div className="sm:col-span-1">
                      <button
                        type="submit"
                        className="mt-2 w-full inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        onClick={() => submit()}
                      >
                        <SearchIcon
                          className="-ml-1 mr-2 h-5 w-5"
                          aria-hidden="true"
                        />
                        検索
                      </button>
                    </div>
                    <div className="sm:col-span-1">
                      <AddMonitoringData
                        windFarm={formState.windFarm}
                        alarmIds={formState.alarmIds}
                      />
                    </div>
                  </div>
                  {/* </CardBody> */}
                </Card>
              </div>
              {/* End left column area */}
            </div>
          </div>

          <div className=" lg:min-w-0 lg:flex-1">
            <div className="h-full py-6 px-4 sm:px-6 lg:px-8">
              {/* Start main area*/}
              <div className="relative h-full" style={{ minHeight: "36rem" }}>
                {selectedForm.windFarm.name === "玉之浦" &&
                  selectedForm.alarmIds.length > 0 && (
                    <Connect
                      query={graphqlOperation(listAlarmTamanouraByIds, {
                        ids: selectedForm.alarmIds.split(","),
                      })}
                      subscription={graphqlOperation(
                        subscriptions.onUpdateAlarmTamanoura
                      )}
                      // onSubscriptionMsg={handleSubmit2}
                    >
                      {({
                        data: { listAlarmTamanouraByIds },
                        loading,
                        error,
                      }) => {
                        if (error) return <h3>Error</h3>;
                        if (loading || !listAlarmTamanouraByIds) {
                          return <LoadingView />;
                        }
                        return (
                          <AlarmTamanouraView
                            alarms={listAlarmTamanouraByIds.items}
                          />
                        );
                      }}
                    </Connect>
                  )}

                {selectedForm.windFarm.name === "れいめい" &&
                  selectedForm.alarmIds.length > 0 && (
                    <Connect
                      query={graphqlOperation(listAlarmReimeiByIds, {
                        ids: selectedForm.alarmIds.split(","),
                      })}
                      subscription={graphqlOperation(
                        subscriptions.onUpdateAlarmReimei
                      )}
                      // onSubscriptionMsg={handleSubmit2}
                    >
                      {({ data: { listAlarmReimeiByIds }, loading, error }) => {
                        if (error) return <h3>Error</h3>;
                        if (loading || !listAlarmReimeiByIds) {
                          return <LoadingView />;
                        }
                        return (
                          <AlarmReimeiView
                            alarms={listAlarmReimeiByIds.items}
                          />
                        );
                      }}
                    </Connect>
                  )}

                {selectedForm.windFarm.name === "渥美" &&
                  selectedForm.alarmIds.length > 0 && (
                    <Connect
                      query={graphqlOperation(listAlarmAtsumiByIds, {
                        ids: selectedForm.alarmIds.split(","),
                      })}
                      subscription={graphqlOperation(
                        subscriptions.onUpdateAlarmAtsumi
                      )}
                      // onSubscriptionMsg={handleSubmit2}
                    >
                      {({ data: { listAlarmAtsumiByIds }, loading, error }) => {
                        if (error) return <h3>Error</h3>;
                        if (loading || !listAlarmAtsumiByIds) {
                          return <LoadingView />;
                        }
                        return (
                          <AlarmAtsumiView
                            alarms={listAlarmAtsumiByIds.items}
                          />
                        );
                      }}
                    </Connect>
                  )}

                {selectedForm.windFarm.name === "野母崎" &&
                  selectedForm.alarmIds.length > 0 && (
                    <Connect
                      query={graphqlOperation(listAlarmNomozakiByIds, {
                        ids: selectedForm.alarmIds.split(","),
                      })}
                      subscription={graphqlOperation(
                        subscriptions.onUpdateAlarmNomozaki
                      )}
                      // onSubscriptionMsg={handleSubmit2}
                    >
                      {({
                        data: { listAlarmNomozakiByIds },
                        loading,
                        error,
                      }) => {
                        if (error) return <h3>Error</h3>;
                        if (loading || !listAlarmNomozakiByIds) {
                          return <LoadingView />;
                        }
                        return (
                          <AlarmNomozakiView
                            alarms={listAlarmNomozakiByIds.items}
                          />
                        );
                      }}
                    </Connect>
                  )}

                {selectedForm.windFarm.name === "富江" &&
                  selectedForm.alarmIds.length > 0 && (
                    <Connect
                      query={graphqlOperation(listAlarmTomieByIds, {
                        ids: selectedForm.alarmIds.split(","),
                      })}
                      subscription={graphqlOperation(
                        subscriptions.onUpdateAlarmTomie
                      )}
                      // onSubscriptionMsg={handleSubmit2}
                    >
                      {({ data: { listAlarmTomieByIds }, loading, error }) => {
                        if (error) return <h3>Error</h3>;
                        if (loading || !listAlarmTomieByIds) {
                          return <LoadingView />;
                        }
                        return (
                          <AlarmTomieView alarms={listAlarmTomieByIds.items} />
                        );
                      }}
                    </Connect>
                  )}

                {selectedForm.windFarm.name === "崎山" &&
                  selectedForm.alarmIds.length > 0 && (
                    <Connect
                      query={graphqlOperation(listAlarmSakiyamaByIds, {
                        ids: selectedForm.alarmIds.split(","),
                      })}
                      subscription={graphqlOperation(
                        subscriptions.onUpdateAlarmSakiyama
                      )}
                      // onSubscriptionMsg={handleSubmit2}
                    >
                      {({
                        data: { listAlarmSakiyamaByIds },
                        loading,
                        error,
                      }) => {
                        if (error) return <h3>Error</h3>;
                        if (loading || !listAlarmSakiyamaByIds) {
                          return <LoadingView />;
                        }
                        return (
                          <AlarmSakiyamaView
                            alarms={listAlarmSakiyamaByIds.items}
                          />
                        );
                      }}
                    </Connect>
                  )}

                {selectedForm.windFarm.name === "愛野" &&
                  selectedForm.alarmIds.length > 0 && (
                    <Connect
                      query={graphqlOperation(listAlarmAinoByIds, {
                        ids: selectedForm.alarmIds.split(","),
                      })}
                      subscription={graphqlOperation(
                        subscriptions.onUpdateAlarmAino
                      )}
                      // onSubscriptionMsg={handleSubmit2}
                    >
                      {({ data: { listAlarmAinoByIds }, loading, error }) => {
                        if (error) return <h3>Error</h3>;
                        if (loading || !listAlarmAinoByIds) {
                          return <LoadingView />;
                        }
                        return (
                          <AlarmAinoView alarms={listAlarmAinoByIds.items} />
                        );
                      }}
                    </Connect>
                  )}
              </div>
              {/* End main area */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlarmList;
