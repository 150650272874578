import React, { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition, Listbox } from "@headlessui/react";
import { CheckIcon, SelectorIcon, PlusIcon } from "@heroicons/react/outline";
import Datetime from "react-datetime";

// import { API, graphqlOperation } from "aws-amplify";
// import * as queries from "src/graphql/queries";

// import * as moment from "moment";
import "moment/locale/ja";
import {
  tamanouraTowers,
  reimeiTowers,
  atsumiTowers,
  tomieTowers,
} from "src/utils/towers";

/**
 * classNames
 * @param  {...any} classes
 * @returns
 */
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const initialState = {
  // modalDemo: false,
  windFarm: "",
  alarmIds: "",
  // Form
  tower: { value: "0", name: "選択してください" },
  // alarmTime: moment().tz("Asia/Tokyo"),
  maxWindSpeed: "",
  avgWindSpeed: "",
  windDirection: "",
  restoreTime: "",
  responsible: "",
  obs: "",
  errors: {
    tower: "",
    alarmTime: "",
  },
};

// const initialErrorState = {
//   tower: "",
//   alarmTime: "",
// };

/**
 * AddMonitoringData
 *
 * @author Thais Watanabe
 */
const AddMonitoringData = (props) => {
  const [windFarmTowers, setWindFarmTowers] = useState([]);
  const [formState, setFormState] = useState(initialState);
  // const [errors, setErrors] = useState(initialErrorState);
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef();

  /**
   * Set input
   * @param {*} key
   * @param {*} value
   */
  function setInput(key, value) {
    setFormState({ ...formState, [key]: value });
  }

  /**
   * useEffect
   */
  useEffect(() => {
    var selected = initialState;
    selected.windFarm = props.windFarm;
    selected.alarmIds = props.alarmIds;
    setFormState(selected);

    if (props.windFarm.name === "玉之浦") {
      setWindFarmTowers(tamanouraTowers);
    } else if (props.windFarm.name === "れいめい") {
      setWindFarmTowers(reimeiTowers);
    } else if (props.windFarm.name === "渥美") {
      setWindFarmTowers(atsumiTowers);
    } else if (props.windFarm.name === "富江") {
      setWindFarmTowers(tomieTowers);
    } else {
      setWindFarmTowers([]);
    }
  }, [props]);

  // async function addTodo() {
  //   // try {
  //   //   if (!formState.name || !formState.description) return;
  //   //   const todo = { ...formState };
  //   //   setTodos([...todos, todo]);
  //   //   setFormState(initialState);
  //   //   await API.graphql(graphqlOperation(createTodo, { input: todo }));
  //   // } catch (err) {
  //   //   console.log("error creating todo:", err);
  //   // }
  // }

  // const handleInputChange = (event) => {
  //   event.preventDefault();

  //   const target = event.target;
  //   const value = target.value;
  //   const name = target.name;

  //   let errors = formState.errors;

  //   switch (name) {
  //     case "tower":
  //       if (value.length < 1) {
  //         errors.tower = "入力してください";
  //       } else if (value === "選択してください") {
  //         errors.tower = "入力してください";
  //       } else {
  //         errors.tower = "";
  //       }
  //       break;
  //     default:
  //       break;
  //   }

  //   this.setState({ errors, [name]: value });
  // };

  // const handleDateTimePicker = (moment, name) => {
  //   try {
  //     this.setState({ [name]: moment.tz("Asia/Tokyo") });
  //   } catch (e) {}
  // };

  // function showNotification(type, message) {
  //   // var options = {};
  //   // options = {
  //   //   place: "tr",
  //   //   message: (
  //   //     <div>
  //   //       {message}
  //   //     </div>
  //   //   ),
  //   //   type: type,
  //   //   icon: "tim-icons icon-bell-55",
  //   //   autoDismiss: 7
  //   // };
  //   // this.refs.notificationAlert.notificationAlert(options);
  // }

  // const validateForm = (errors) => {
  //   let valid = true;
  //   Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
  //   return valid;
  // };

  // async function submit() {
  //   const { v4: uuidv4 } = require("uuid");
  //   const { onCreate } = this.props;
  //   const alarmIds = formState.alarmId.split(",");

  //   if (this.validateForm(formState.errors)) {
  //     for (var i = 0; i < alarmIds.length; ++i) {
  //       const input = {
  //         id: uuidv4(),
  //         windFarm: formState.windFarm,
  //         alarmId: alarmIds[i],
  //         tower: formState.tower,
  //         alarmTime: formState.alarmTime,
  //         responsible: formState.responsible,
  //       };

  //       let alarm;

  //       switch (formState.windFarm) {
  //         case "玉之浦":
  //           alarm = await API.graphql(
  //             graphqlOperation(queries.getAlarmTamanoura, { id: alarmIds[i] })
  //           );
  //           input.alarmName = alarm.data.getAlarmTamanoura.name;
  //           input.alarmType = alarm.data.getAlarmTamanoura.reset;
  //           break;
  //         case "れいめい":
  //           alarm = await API.graphql(
  //             graphqlOperation(queries.getAlarmReimei, { id: alarmIds[i] })
  //           );
  //           input.alarmName = alarm.data.getAlarmReimei.name;
  //           input.alarmType =
  //             alarm.data.getAlarmReimei.falha +
  //             "/" +
  //             alarm.data.getAlarmReimei.reset;
  //           break;
  //         case "渥美":
  //           alarm = await API.graphql(
  //             graphqlOperation(queries.getAlarmAtsumi, { id: alarmIds[i] })
  //           );
  //           input.alarmName = alarm.data.getAlarmAtsumi.name;
  //           input.alarmType = alarm.data.getAlarmAtsumi.acknowledge;
  //           break;
  //         case "野母崎":
  //           alarm = await API.graphql(
  //             graphqlOperation(queries.getAlarmNomozaki, { id: alarmIds[i] })
  //           );
  //           const error =
  //             (alarm.data.getAlarmNomozaki.errorHeavy === "〇" ? "重" : "軽") +
  //             "/";
  //           const alarmError =
  //             alarm.data.getAlarmNomozaki.errorResetAuto === "〇"
  //               ? "自動"
  //               : alarm.data.getAlarmNomozaki.errorResetRemote === "〇"
  //               ? "遠隔"
  //               : alarm.data.getAlarmNomozaki.errorResetLocal === "〇"
  //               ? "現地"
  //               : "-";
  //           input.alarmName = alarm.data.getAlarmNomozaki.name;
  //           input.alarmType = error + alarmError;
  //           break;
  //         case "富江":
  //           alarm = await API.graphql(
  //             graphqlOperation(queries.getAlarmTomie, { id: alarmIds[i] })
  //           );
  //           input.alarmName = alarm.data.getAlarmTomie.name;
  //           input.alarmType =
  //             alarm.data.getAlarmTomie.resetAuto === "〇"
  //               ? "自動"
  //               : alarm.data.getAlarmTomie.resetRemote === "〇"
  //               ? "遠隔"
  //               : alarm.data.getAlarmTomie.resetLocal === "〇"
  //               ? "現地"
  //               : "-";
  //           break;
  //         case "崎山":
  //           alarm = await API.graphql(
  //             graphqlOperation(queries.getAlarmSakiyama, { id: alarmIds[i] })
  //           );
  //           input.alarmName = alarm.data.getAlarmSakiyama.name;
  //           input.alarmType =
  //             alarm.data.getAlarmSakiyama.falha +
  //             "/" +
  //             alarm.data.getAlarmSakiyama.reset;
  //           break;
  //         case "愛野":
  //           alarm = await API.graphql(
  //             graphqlOperation(queries.getAlarmAino, { id: alarmIds[i] })
  //           );
  //           input.alarmName = alarm.data.getAlarmAino.name;
  //           input.alarmType = alarm.data.getAlarmAino.reset;
  //           break;
  //         default:
  //           break;
  //       }

  //       if (formState.obs.length > 0) {
  //         input.obs = formState.obs;
  //       }

  //       if (formState.maxWindSpeed.length > 0) {
  //         input.maxWindSpeed = formState.maxWindSpeed;
  //       }

  //       if (formState.avgWindSpeed.length > 0) {
  //         input.avgWindSpeed = formState.avgWindSpeed;
  //       }

  //       if (formState.windDirection.length > 0) {
  //         input.windDirection = formState.windDirection;
  //       }

  //       if (formState.restoreTime) {
  //         input.restoreTime = formState.restoreTime;
  //       }

  //       try {
  //         await onCreate({ input });
  //         this.showNotification("success", "保存しました！");
  //       } catch (err) {
  //         this.showNotification("danger", "エラーが発生しました！");
  //         console.error(err);
  //       }
  //     }
  //     this.toggleModalDemo();
  //   } else {
  //     console.error("Invalid Form");
  //   }
  // }

  function toggle() {
    // if (
    //   formState.windFarm == null ||
    //   formState.windFarm === "" ||
    //   formState.alarmId == null ||
    //   formState.alarmId === ""
    // ) {
    // var options = {};
    // options = {
    //   place: "br",
    //   message: (
    //     <div>
    //       風力発電所とアラームIDsを入力してください
    //     </div>
    //   ),
    //   type: "danger",
    //   icon: "tim-icons icon-bell-55",
    //   autoDismiss: 7
    // };
    // this.refs.notificationAlert.notificationAlert(options);
    // } else {
    // this.setState({ modalDemo: !formState.modalDemo });
    // this.resetState();
    setOpen(!open);
    // }
  }

  return (
    <>
      <button
        type="button"
        className="w-full inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        onClick={toggle}
      >
        <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
        監視日報に追加
      </button>

      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          open={open}
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full">
                  <div className="bg-gray-50 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      監視日報
                    </h3>
                  </div>
                <div className="bg-white sm:p-6 sm:pb-4">
                  <div className="mt-6 grid grid-cols-3 gap-y-6 gap-x-4 sm:grid-cols-6">
                    <div className="sm:col-span-2">
                      <label
                        htmlFor="wind_farm"
                        className="block text-sm font-medium text-blue-gray-900"
                      >
                        風力発電所
                      </label>
                      <input
                        type="text"
                        name="wind_farm"
                        id="wind_farm"
                        value={formState.windFarm.name}
                        disabled={true}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500"
                      />
                    </div>

                    <div className="sm:col-span-2">
                      <label
                        htmlFor="alarm_ids"
                        className="block text-sm font-medium text-blue-gray-900"
                      >
                        アラームIDs
                      </label>
                      <input
                        type="text"
                        name="alarm_ids"
                        id="alarm_ids"
                        value={formState.alarmIds}
                        disabled={true}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500"
                      />
                    </div>

                    <div className="sm:col-span-2">
                      <label
                        htmlFor="responsible"
                        className="block text-sm font-medium text-blue-gray-900"
                      >
                        担当者
                      </label>
                      <input
                        type="text"
                        name="responsible"
                        id="responsible"
                        value={formState.responsible}
                        disabled={true}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500"
                      />
                    </div>

                    <div className="sm:col-span-2">
                      <Listbox
                        value={formState.tower}
                        onChange={(event) => setInput("tower", event)}
                      >
                        {({ open }) => (
                          <>
                            <Listbox.Label className="block text-sm font-medium text-gray-700">
                              号機
                            </Listbox.Label>
                            <div className="mt-1 relative">
                              <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                                <span className="block truncate">
                                  {formState.tower.name}
                                </span>
                                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                  <SelectorIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </span>
                              </Listbox.Button>

                              <Transition
                                show={open}
                                as={React.Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options
                                  static
                                  className="absolute mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                                >
                                  {windFarmTowers.map((tower) => (
                                    <Listbox.Option
                                      key={tower.value}
                                      className={({ active }) =>
                                        classNames(
                                          active
                                            ? "text-white bg-blue-600"
                                            : "text-gray-900",
                                          "cursor-default select-none relative py-2 pl-8 pr-4"
                                        )
                                      }
                                      value={tower}
                                    >
                                      {({ selected, active }) => (
                                        <>
                                          <span
                                            className={classNames(
                                              selected
                                                ? "font-semibold"
                                                : "font-normal",
                                              "block truncate"
                                            )}
                                          >
                                            {tower.name}
                                          </span>

                                          {selected ? (
                                            <span
                                              className={classNames(
                                                active
                                                  ? "text-white"
                                                  : "text-blue-600",
                                                "absolute inset-y-0 left-0 flex items-center pl-1.5"
                                              )}
                                            >
                                              <CheckIcon
                                                className="h-5 w-5"
                                                aria-hidden="true"
                                              />
                                            </span>
                                          ) : null}
                                        </>
                                      )}
                                    </Listbox.Option>
                                  ))}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </>
                        )}
                      </Listbox>
                    </div>

                    <div className="sm:col-span-2">
                      <label
                        htmlFor="alarm_time"
                        className="block text-sm font-medium text-blue-gray-900"
                      >
                        発生時刻
                      </label>
                      <Datetime
                        id="alarm_time"
                        name="alarm_time"
                        inputProps={{ placeholder: "発生時刻" }}
                        locale="ja"
                        value={formState.alarmTime}
                        // onChange={(moment) =>
                        //   this.handleDateTimePicker(moment, "alarmTime")
                        // }
                        required={true}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500"
                      />
                    </div>

                    <div className="sm:col-span-2">
                      <label
                        htmlFor="restore_time"
                        className="block text-sm font-medium text-blue-gray-900"
                      >
                        復旧時刻
                      </label>
                      <Datetime
                        id="restore_time"
                        name="restore_time"
                        inputProps={{ placeholder: "復旧時刻" }}
                        locale="ja"
                        value={formState.restoreTime}
                        // onChange={(moment) =>
                        //   this.handleDateTimePicker(moment, "alarmTime")
                        // }
                        required={true}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500"
                      />
                    </div>
                    
                    <div className="sm:col-span-2">
                      <label
                        htmlFor="max_wind_speed"
                        className="block text-sm font-medium text-blue-gray-900"
                      >
                        最大
                      </label>
                      <input
                        type="text"
                        name="max_wind_speed"
                        id="max_wind_speed"
                        value={formState.maxWindSpeed}
                        // onChange={(event) =>
                        //   setInput("alarmIds", event.target.value)
                        // }
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500"
                      />
                    </div>

                    <div className="sm:col-span-2">
                      <label
                        htmlFor="avg_wind_speed"
                        className="block text-sm font-medium text-blue-gray-900"
                      >
                        平均
                      </label>
                      <input
                        type="text"
                        name="avg_wind_speed"
                        id="avg_wind_speed"
                        value={formState.avgWindSpeed}
                        // onChange={(event) =>
                        //   setInput("alarmIds", event.target.value)
                        // }
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500"
                      />
                    </div>

                    <div className="sm:col-span-2">
                      <label
                        htmlFor="wind_direction"
                        className="block text-sm font-medium text-blue-gray-900"
                      >
                        風向
                      </label>
                      <input
                        type="text"
                        name="wind_direction"
                        id="wind_direction"
                        value={formState.windDirection}
                        // onChange={(event) =>
                        //   setInput("alarmIds", event.target.value)
                        // }
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500"
                      />
                    </div>
                    <div className="sm:col-span-6">
                      <label
                        htmlFor="obs"
                        className="block text-sm font-medium text-blue-gray-900"
                      >
                        備考欄
                      </label>
                      <input
                        type="text"
                        name="obs"
                        id="obs"
                        value={formState.obs}
                        // onChange={(event) =>
                        //   setInput("alarmIds", event.target.value)
                        // }
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500"
                      />
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                  >
                    保存する
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    キャンセル
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default AddMonitoringData;
