import React, { useState, useEffect } from "react";
import Datetime from "react-datetime";
import toast from "react-hot-toast";
import * as moment from "moment-timezone";
import "moment/locale/ja";
import { SearchIcon } from "@heroicons/react/solid";

import { API, graphqlOperation } from "aws-amplify";
import { listWorkControls } from "src/graphql/queries";
import {
  onCreateWorkControl,
  onUpdateWorkControl,
  onDeleteWorkControl,
} from "src/graphql/subscriptions";

import Card from "src/components/Layout/Card";
import TimeoutNotification from "src/components/Notification/TimeoutNotification";
import WorkControlTable from "src/views/work-control/WorkControlTable";
import AddWorkControlData from "src/views/work-control/AddWorkControlData";

const zone = "Asia/Tokyo";

/**
 * WorkControl
 *
 * @author Thais Watanabe
 */
const WorkControl = () => {
  const [workControls, setWorkControls] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [iterateWorkControls, setIterateWorkControls] = useState([]);
  const [dateStart, setDateStart] = useState(moment().tz(zone));
  const [dateEnd, setDateEnd] = useState(moment().tz(zone));

  /**
   * useEffect
   */
  useEffect(() => {
    fetchWorkControls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * useEffect
   */
  useEffect(() => {
    // On Create
    const onCreateSubscription = API.graphql(
      graphqlOperation(onCreateWorkControl)
    ).subscribe({
      next: (data) => {
        const added = data.value.data.onCreateWorkControl;
        setWorkControls((prevState) => [...prevState, added]);
      },
      error: (error) => {
        showTimeoutNotification();
        console.log(error);
      },
    });

    // On Update
    const onUpdateSubscription = API.graphql(
      graphqlOperation(onUpdateWorkControl)
    ).subscribe({
      next: (data) => {
        fetchWorkControls();
      },
      error: (error) => {
        showTimeoutNotification();
        console.log(error);
      },
    });

    // On Delete
    const onDeleteSubscription = API.graphql(
      graphqlOperation(onDeleteWorkControl)
    ).subscribe({
      next: (data) => {
        const deleted = data.value.data.onDeleteWorkControl;
        setWorkControls((prevState) =>
          prevState.filter((item) => item.id !== deleted.id)
        );
      },
      error: (error) => {
        showTimeoutNotification();
        console.log(error);
      },
    });

    return () => {
      onCreateSubscription.unsubscribe();
      onUpdateSubscription.unsubscribe();
      onDeleteSubscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * useEffect
   */
  useEffect(() => {
    if (!isLoading) {
      setWorkControls(sortData(iterateWorkControls));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  /**
   * fetchWorkControls
   */
  async function fetchWorkControls() {
    try {
      setLoading(true);
      setWorkControls([]);
      setIterateWorkControls([]);
      query(null);
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * query
   * @param {*} nextToken
   */
  async function query(nextToken) {
    const workControlsData = await API.graphql(
      graphqlOperation(listWorkControls, {
        filter: { startTime: { between: handleDateInput() } },
        limit: 1000,
        nextToken: nextToken,
      })
    );

    const fetchedWorkControls = workControlsData.data.listWorkControls.items;

    if (fetchedWorkControls.length > 0) {
      fetchedWorkControls.forEach((item) => {
        setIterateWorkControls((prevState) => [...prevState, item]);
      });
    }

    if (workControlsData.data.listWorkControls.nextToken) {
      query(workControlsData.data.listWorkControls.nextToken);
    } else {
      setLoading(false);
    }
  }

  /**
   * handleDateInput
   * @returns
   */
  function handleDateInput() {
    var wDateStart = moment(dateStart).tz(zone).startOf("day").utc().format();
    var wDateEnd = moment(dateEnd).tz(zone).endOf("day").utc().format();
    return [wDateStart, wDateEnd];
  }

  /**
   * sortData
   * @param {*} data
   * @returns
   */
  function sortData(data) {
    return data.sort(function (a, b) {
      return new Date(a.startTime) - new Date(b.startTime);
    });
  }

  /**
   * showTimeoutNotification
   */
  function showTimeoutNotification() {
    toast.custom((t) => <TimeoutNotification t={t} />, {
      duration: Infinity,
    });
  }

  return (
    <div className="w-full">
      <div className="flex-grow w-full lg:flex">
        {/* Left sidebar & main wrapper */}
        <div className="flex-1 min-w-0 xl:flex">
          <div className="border-b border-gray-200 xl:border-b-0 xl:flex-shrink-0 xl:w-64 xl:border-r xl:border-gray-200 ">
            <div className="h-full pl-4 pr-4 py-6 sm:pl-4 lg:pl-8 xl:pl-4">
              {/* Start left column area */}
              <div className="h-full relative" style={{ minHeight: "12rem" }}>
                <Card title="作業管理一覧">
                  <div className="grid grid-cols-1 gap-y-1 sm:grid-cols-1 sm:gap-x-1">
                    <div className="sm:col-span-1">
                      <label
                        htmlFor="dateStart"
                        className="block text-sm font-medium text-gray-700"
                      >
                        日付から
                      </label>
                      <Datetime
                        id="dateStart"
                        name="dateStart"
                        inputProps={{ placeholder: "日付" }}
                        locale="ja"
                        value={dateStart}
                        onChange={(moment) => setDateStart(moment.tz(zone))}
                        required={true}
                        timeFormat={false}
                        className="mt-1 border border-gray-300 rounded-md shadow-sm text-gray-500 sm:text-sm focus:ring-blue-500 focus:border-blue-500"
                      />
                    </div>
                    <div className="mt-3 sm:col-span-1">
                      <label
                        htmlFor="dateEnd"
                        className="block text-sm font-medium text-gray-700"
                      >
                        日付まで
                      </label>
                      <Datetime
                        id="dateEnd"
                        name="dateEnd"
                        inputProps={{ placeholder: "日付" }}
                        locale="ja"
                        value={dateEnd}
                        onChange={(moment) => setDateEnd(moment.tz(zone))}
                        required={true}
                        timeFormat={false}
                        className="mt-1 w-full border border-gray-300 rounded-md shadow-sm text-gray-500 sm:text-sm focus:ring-blue-500 focus:border-blue-500"
                      />
                    </div>
                    <div className="mt-3 sm:col-span-1">
                      <button
                        type="submit"
                        className="mt-2 w-full inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        onClick={() => fetchWorkControls()}
                      >
                        <SearchIcon
                          className="-ml-1 mr-2 h-5 w-5"
                          aria-hidden="true"
                        />
                        検索
                      </button>
                    </div>
                    <div className="sm:col-span-1">
                      <AddWorkControlData />
                    </div>
                  </div>
                  {/* </CardBody> */}
                </Card>
              </div>
              {/* End left column area */}
            </div>
          </div>

          <div className=" lg:min-w-0 lg:flex-1">
            <div className="h-full py-6 px-4 sm:px-6 lg:px-8">
              {/* Start main area*/}
              <div className="relative h-full" style={{ minHeight: "36rem" }}>
                <WorkControlTable
                  workControls={workControls}
                  isLoading={isLoading}
                  dateStart={dateStart}
                  dateEnd={dateEnd}
                  fetchWorkControls={fetchWorkControls}
                />
              </div>
              {/* End main area */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkControl;
