import React from "react";
import { Route } from "react-router-dom";
import {
  AmplifyAuthenticator,
  AmplifyContainer,
  AmplifySignIn,
} from "@aws-amplify/ui-react";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";

import Layout from "src/components/Layout/Layout.jsx";
import "./App.css";

/**
 * The App Component
 *
 * @author Thais Watanabe
 */
const App = () => {
  const [authState, setAuthState] = React.useState();
  const [user, setUser] = React.useState();

  React.useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData);
    });
  }, []);

  return authState === AuthState.SignedIn && user ? (
    <React.StrictMode>
      <Route path="/" render={(props) => <Layout {...props} />} />
    </React.StrictMode>
  ) : (
    <AmplifyContainer>
      <AmplifyAuthenticator>
        <AmplifySignIn slot="sign-in" hideSignUp></AmplifySignIn>
      </AmplifyAuthenticator>
    </AmplifyContainer>
  );
};

export default App;
