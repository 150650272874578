import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { Connect } from "aws-amplify-react";
import * as moment from "moment-timezone";
import { withScriptjs, withGoogleMap, GoogleMap } from "react-google-maps";
import toast from "react-hot-toast";

import "moment/locale/ja";

import {
  onCreateWorkControl,
  onUpdateWorkControl,
  onDeleteWorkControl,
} from "src/graphql/subscriptions";
import {
  listWorkControls,
  listWindFarmInformations,
} from "src/graphql/queries";

import WorkMarker from "src/views/map/WorkMarker";
import { styles } from "src/views/map/styles";

import TimeoutNotification from "src/components/Notification/TimeoutNotification";

const zone = "Asia/Tokyo";

/**
 * The Map page
 */
const Map = () => {
  const [workControls, setWorkControls] = useState([]);
  const [iterateWorkControls, setIterateWorkControls] = useState([]);
  const [isLoading, setLoading] = useState(true);

  /* useEffect */
  useEffect(() => {
    fetchWorkControls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * useEffect
   */
  useEffect(() => {
    // On Create
    const onCreateSubscription = API.graphql(
      graphqlOperation(onCreateWorkControl)
    ).subscribe({
      next: (data) => {
        const added = data.value.data.onCreateWorkControl;
        setWorkControls((prevState) => [...prevState, added]);
      },
      error: (error) => {
        showTimeoutNotification();
        console.log(error);
      },
    });

    // On Update
    const onUpdateSubscription = API.graphql(
      graphqlOperation(onUpdateWorkControl)
    ).subscribe({
      next: (data) => {
        fetchWorkControls();
      },
      error: (error) => {
        showTimeoutNotification();
        console.log(error);
      },
    });

    // On Delete
    const onDeleteSubscription = API.graphql(
      graphqlOperation(onDeleteWorkControl)
    ).subscribe({
      next: (data) => {
        const deleted = data.value.data.onDeleteWorkControl;
        setWorkControls((prevState) =>
          prevState.filter((item) => item.id !== deleted.id)
        );
      },
      error: (error) => {
        showTimeoutNotification();
        console.log(error);
      },
    });

    return () => {
      onCreateSubscription.unsubscribe();
      onUpdateSubscription.unsubscribe();
      onDeleteSubscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * useEffect
   */
  useEffect(() => {
    if (!isLoading) {
      setWorkControls(iterateWorkControls);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  /**
   * fetchInspectionDetails
   */
  async function fetchWorkControls() {
    try {
      setLoading(true);
      setWorkControls([]);
      setIterateWorkControls([]);
      query(null);
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * query
   * @param {*} nextToken
   */
  async function query(nextToken) {
    const workControlsData = await API.graphql(
      graphqlOperation(listWorkControls, {
        filter: {
          startTime: {
            between: [
              moment()
                .tz(zone)
                .hours(0)
                .minutes(0)
                .seconds(0)
                .milliseconds(0)
                .utc()
                .format(),
              moment()
                .tz(zone)
                .hours(23)
                .minutes(59)
                .seconds(59)
                .milliseconds(59)
                .utc()
                .format(),
            ],
          },
        },
        limit: 1000,
        nextToken: nextToken,
      })
    );

    const fetchedWorkControls = workControlsData.data.listWorkControls.items;

    if (fetchedWorkControls.length > 0) {
      fetchedWorkControls.forEach((item) => {
        setIterateWorkControls((prevState) => [...prevState, item]);
      });
    }

    if (workControlsData.data.listWorkControls.nextToken) {
      query(workControlsData.data.listWorkControls.nextToken);
    } else {
      setLoading(false);
    }
  }

  /**
   * showTimeoutNotification
   */
  function showTimeoutNotification() {
    toast.custom((t) => <TimeoutNotification t={t} />, {
      duration: Infinity,
    });
  }

  // Map Wrapper
  const MapWrapper = withScriptjs(
    withGoogleMap((props) => (
      <GoogleMap
        defaultZoom={6}
        defaultCenter={{ lat: 38.147585, lng: 136.332417 }}
        defaultOptions={{
          scrollwheel: false,
          styles: styles,
        }}
      >
        <WorkControlView />
      </GoogleMap>
    ))
  );

  const WorkControlView = () =>
    workControls.map(
      (work, i) =>
        work.endTime === null && (
          <Connect
            key={i}
            query={graphqlOperation(listWindFarmInformations, {
              filter: { name: { eq: work.windFarm } },
              limit: 1000,
            })}
          >
            {({ data: { listWindFarmInformations }, loading, error }) => {
              if (error) return;
              if (loading || !listWindFarmInformations) {
                return;
              }
              var windFarmInfo = listWindFarmInformations.items[0];

              if (windFarmInfo.latlong != null) {
                var latlong = windFarmInfo.latlong.split(",");

                return (
                  <WorkMarker
                    key={i}
                    work={work}
                    lat={parseFloat(latlong[0])}
                    long={parseFloat(latlong[1])}
                  />
                );
              }
            }}
          </Connect>
        )
    );

  return (
    <>
      <div className="w-full">
        <div
          id="map"
          className="map"
          style={{ position: "relative", overflow: "hidden", height: "100vh" }}
        >
          <MapWrapper
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCg3r6Dr9GE4yZuoj_Zga2HV0qInJQ_Cio"
            loadingElement={<div style={{ height: "100%" }} />}
            containerElement={<div style={{ height: "100%" }} />}
            mapElement={<div style={{ height: "100%" }} />}
          />
        </div>
      </div>
    </>
  );
};

export default Map;
