var tablei18n = {
  body: {
    emptyDataSourceMessage: '表示するデータはありません',
    deleteTooltip: '削除',
    editTooltip: '修正',
    editRow: {
      deleteText: 'この行を削除してもよろしいですか？',
      cancelTooltip: 'キャンセル',
      saveTooltip: '保存'
    }
  },
  header: {
    actions: '行動'
  },
  pagination: {
    labelRowsSelect: '行',
    labelRowsPerPage: 'ページごとの行:',
    firstAriaLabel: '一ページ目',
    firstTooltip: '一ページ目',
    previousAriaLabel: '前のページ',
    previousTooltip: '前のページ',
    nextAriaLabel: '次のページ',
    nextTooltip: '次のページ',
    lastAriaLabel: '最後のページ',
    lastTooltip: '最後のページ'
  },
  toolbar: {
    exportTitle: 'ダウンロード',
    exportAriaLabel: 'ダウンロード',
    exportName: 'CSVとしてダウンロード',
    searchTooltip: '探索',
    searchPlaceholder: '探索'
  }
};
export default tablei18n;