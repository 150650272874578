/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createMonitoringReport = /* GraphQL */ `
  mutation CreateMonitoringReport(
    $input: CreateMonitoringReportInput!
    $condition: ModelMonitoringReportConditionInput
  ) {
    createMonitoringReport(input: $input, condition: $condition) {
      id
      windFarm
      tower
      alarmId
      alarmName
      alarmType
      alarmTime
      restoreTime
      situation
      maxWindSpeed
      avgWindSpeed
      windDirection
      responsible
      obs
      createdAt
      updatedAt
    }
  }
`;
export const updateMonitoringReport = /* GraphQL */ `
  mutation UpdateMonitoringReport(
    $input: UpdateMonitoringReportInput!
    $condition: ModelMonitoringReportConditionInput
  ) {
    updateMonitoringReport(input: $input, condition: $condition) {
      id
      windFarm
      tower
      alarmId
      alarmName
      alarmType
      alarmTime
      restoreTime
      situation
      maxWindSpeed
      avgWindSpeed
      windDirection
      responsible
      obs
      createdAt
      updatedAt
    }
  }
`;
export const deleteMonitoringReport = /* GraphQL */ `
  mutation DeleteMonitoringReport(
    $input: DeleteMonitoringReportInput!
    $condition: ModelMonitoringReportConditionInput
  ) {
    deleteMonitoringReport(input: $input, condition: $condition) {
      id
      windFarm
      tower
      alarmId
      alarmName
      alarmType
      alarmTime
      restoreTime
      situation
      maxWindSpeed
      avgWindSpeed
      windDirection
      responsible
      obs
      createdAt
      updatedAt
    }
  }
`;
export const createAlarmTamanoura = /* GraphQL */ `
  mutation CreateAlarmTamanoura(
    $input: CreateAlarmTamanouraInput!
    $condition: ModelAlarmTamanouraConditionInput
  ) {
    createAlarmTamanoura(input: $input, condition: $condition) {
      id
      name
      reset
      repeatingCount
      detectionSensor
      cause
      detectionConditions
      interaction
      order
      createdAt
      updatedAt
    }
  }
`;
export const updateAlarmTamanoura = /* GraphQL */ `
  mutation UpdateAlarmTamanoura(
    $input: UpdateAlarmTamanouraInput!
    $condition: ModelAlarmTamanouraConditionInput
  ) {
    updateAlarmTamanoura(input: $input, condition: $condition) {
      id
      name
      reset
      repeatingCount
      detectionSensor
      cause
      detectionConditions
      interaction
      order
      createdAt
      updatedAt
    }
  }
`;
export const deleteAlarmTamanoura = /* GraphQL */ `
  mutation DeleteAlarmTamanoura(
    $input: DeleteAlarmTamanouraInput!
    $condition: ModelAlarmTamanouraConditionInput
  ) {
    deleteAlarmTamanoura(input: $input, condition: $condition) {
      id
      name
      reset
      repeatingCount
      detectionSensor
      cause
      detectionConditions
      interaction
      order
      createdAt
      updatedAt
    }
  }
`;
export const createAlarmReimei = /* GraphQL */ `
  mutation CreateAlarmReimei(
    $input: CreateAlarmReimeiInput!
    $condition: ModelAlarmReimeiConditionInput
  ) {
    createAlarmReimei(input: $input, condition: $condition) {
      id
      name
      level
      falha
      reset
      description
      interaction1
      interaction2
      order
      createdAt
      updatedAt
    }
  }
`;
export const updateAlarmReimei = /* GraphQL */ `
  mutation UpdateAlarmReimei(
    $input: UpdateAlarmReimeiInput!
    $condition: ModelAlarmReimeiConditionInput
  ) {
    updateAlarmReimei(input: $input, condition: $condition) {
      id
      name
      level
      falha
      reset
      description
      interaction1
      interaction2
      order
      createdAt
      updatedAt
    }
  }
`;
export const deleteAlarmReimei = /* GraphQL */ `
  mutation DeleteAlarmReimei(
    $input: DeleteAlarmReimeiInput!
    $condition: ModelAlarmReimeiConditionInput
  ) {
    deleteAlarmReimei(input: $input, condition: $condition) {
      id
      name
      level
      falha
      reset
      description
      interaction1
      interaction2
      order
      createdAt
      updatedAt
    }
  }
`;
export const createAlarmAtsumi = /* GraphQL */ `
  mutation CreateAlarmAtsumi(
    $input: CreateAlarmAtsumiInput!
    $condition: ModelAlarmAtsumiConditionInput
  ) {
    createAlarmAtsumi(input: $input, condition: $condition) {
      id
      name
      state
      acknowledge
      cause
      interaction
      order
      createdAt
      updatedAt
    }
  }
`;
export const updateAlarmAtsumi = /* GraphQL */ `
  mutation UpdateAlarmAtsumi(
    $input: UpdateAlarmAtsumiInput!
    $condition: ModelAlarmAtsumiConditionInput
  ) {
    updateAlarmAtsumi(input: $input, condition: $condition) {
      id
      name
      state
      acknowledge
      cause
      interaction
      order
      createdAt
      updatedAt
    }
  }
`;
export const deleteAlarmAtsumi = /* GraphQL */ `
  mutation DeleteAlarmAtsumi(
    $input: DeleteAlarmAtsumiInput!
    $condition: ModelAlarmAtsumiConditionInput
  ) {
    deleteAlarmAtsumi(input: $input, condition: $condition) {
      id
      name
      state
      acknowledge
      cause
      interaction
      order
      createdAt
      updatedAt
    }
  }
`;
export const createAlarmNomozaki = /* GraphQL */ `
  mutation CreateAlarmNomozaki(
    $input: CreateAlarmNomozakiInput!
    $condition: ModelAlarmNomozakiConditionInput
  ) {
    createAlarmNomozaki(input: $input, condition: $condition) {
      id
      name
      contents
      stopFrequency
      stopTime
      stopInterruption
      errorHeavy
      errorLight
      errorResetLocal
      errorResetRemote
      errorResetAuto
      alarmWarning
      alarmResetLocal
      alarmResetRemote
      alarmResetAuto
      interaction
      order
      createdAt
      updatedAt
    }
  }
`;
export const updateAlarmNomozaki = /* GraphQL */ `
  mutation UpdateAlarmNomozaki(
    $input: UpdateAlarmNomozakiInput!
    $condition: ModelAlarmNomozakiConditionInput
  ) {
    updateAlarmNomozaki(input: $input, condition: $condition) {
      id
      name
      contents
      stopFrequency
      stopTime
      stopInterruption
      errorHeavy
      errorLight
      errorResetLocal
      errorResetRemote
      errorResetAuto
      alarmWarning
      alarmResetLocal
      alarmResetRemote
      alarmResetAuto
      interaction
      order
      createdAt
      updatedAt
    }
  }
`;
export const deleteAlarmNomozaki = /* GraphQL */ `
  mutation DeleteAlarmNomozaki(
    $input: DeleteAlarmNomozakiInput!
    $condition: ModelAlarmNomozakiConditionInput
  ) {
    deleteAlarmNomozaki(input: $input, condition: $condition) {
      id
      name
      contents
      stopFrequency
      stopTime
      stopInterruption
      errorHeavy
      errorLight
      errorResetLocal
      errorResetRemote
      errorResetAuto
      alarmWarning
      alarmResetLocal
      alarmResetRemote
      alarmResetAuto
      interaction
      order
      createdAt
      updatedAt
    }
  }
`;
export const createAlarmTomie = /* GraphQL */ `
  mutation CreateAlarmTomie(
    $input: CreateAlarmTomieInput!
    $condition: ModelAlarmTomieConditionInput
  ) {
    createAlarmTomie(input: $input, condition: $condition) {
      id
      name
      resetLocal
      resetRemote
      resetAuto
      interaction
      order
      createdAt
      updatedAt
    }
  }
`;
export const updateAlarmTomie = /* GraphQL */ `
  mutation UpdateAlarmTomie(
    $input: UpdateAlarmTomieInput!
    $condition: ModelAlarmTomieConditionInput
  ) {
    updateAlarmTomie(input: $input, condition: $condition) {
      id
      name
      resetLocal
      resetRemote
      resetAuto
      interaction
      order
      createdAt
      updatedAt
    }
  }
`;
export const deleteAlarmTomie = /* GraphQL */ `
  mutation DeleteAlarmTomie(
    $input: DeleteAlarmTomieInput!
    $condition: ModelAlarmTomieConditionInput
  ) {
    deleteAlarmTomie(input: $input, condition: $condition) {
      id
      name
      resetLocal
      resetRemote
      resetAuto
      interaction
      order
      createdAt
      updatedAt
    }
  }
`;
export const createAlarmSakiyama = /* GraphQL */ `
  mutation CreateAlarmSakiyama(
    $input: CreateAlarmSakiyamaInput!
    $condition: ModelAlarmSakiyamaConditionInput
  ) {
    createAlarmSakiyama(input: $input, condition: $condition) {
      id
      name
      level
      falha
      reset
      description
      coping
      interaction
      order
      createdAt
      updatedAt
    }
  }
`;
export const updateAlarmSakiyama = /* GraphQL */ `
  mutation UpdateAlarmSakiyama(
    $input: UpdateAlarmSakiyamaInput!
    $condition: ModelAlarmSakiyamaConditionInput
  ) {
    updateAlarmSakiyama(input: $input, condition: $condition) {
      id
      name
      level
      falha
      reset
      description
      coping
      interaction
      order
      createdAt
      updatedAt
    }
  }
`;
export const deleteAlarmSakiyama = /* GraphQL */ `
  mutation DeleteAlarmSakiyama(
    $input: DeleteAlarmSakiyamaInput!
    $condition: ModelAlarmSakiyamaConditionInput
  ) {
    deleteAlarmSakiyama(input: $input, condition: $condition) {
      id
      name
      level
      falha
      reset
      description
      coping
      interaction
      order
      createdAt
      updatedAt
    }
  }
`;
export const createAlarmAino = /* GraphQL */ `
  mutation CreateAlarmAino(
    $input: CreateAlarmAinoInput!
    $condition: ModelAlarmAinoConditionInput
  ) {
    createAlarmAino(input: $input, condition: $condition) {
      id
      name
      description
      procedure
      alert
      reset
      interaction
      order
      createdAt
      updatedAt
    }
  }
`;
export const updateAlarmAino = /* GraphQL */ `
  mutation UpdateAlarmAino(
    $input: UpdateAlarmAinoInput!
    $condition: ModelAlarmAinoConditionInput
  ) {
    updateAlarmAino(input: $input, condition: $condition) {
      id
      name
      description
      procedure
      alert
      reset
      interaction
      order
      createdAt
      updatedAt
    }
  }
`;
export const deleteAlarmAino = /* GraphQL */ `
  mutation DeleteAlarmAino(
    $input: DeleteAlarmAinoInput!
    $condition: ModelAlarmAinoConditionInput
  ) {
    deleteAlarmAino(input: $input, condition: $condition) {
      id
      name
      description
      procedure
      alert
      reset
      interaction
      order
      createdAt
      updatedAt
    }
  }
`;
export const createWorkControl = /* GraphQL */ `
  mutation CreateWorkControl(
    $input: CreateWorkControlInput!
    $condition: ModelWorkControlConditionInput
  ) {
    createWorkControl(input: $input, condition: $condition) {
      id
      date
      windFarm
      tower
      startTime
      endTimePrev
      technicians
      workName
      safetyPriority
      endTime
      author
      maker
      createdAt
      updatedAt
    }
  }
`;
export const updateWorkControl = /* GraphQL */ `
  mutation UpdateWorkControl(
    $input: UpdateWorkControlInput!
    $condition: ModelWorkControlConditionInput
  ) {
    updateWorkControl(input: $input, condition: $condition) {
      id
      date
      windFarm
      tower
      startTime
      endTimePrev
      technicians
      workName
      safetyPriority
      endTime
      author
      maker
      createdAt
      updatedAt
    }
  }
`;
export const deleteWorkControl = /* GraphQL */ `
  mutation DeleteWorkControl(
    $input: DeleteWorkControlInput!
    $condition: ModelWorkControlConditionInput
  ) {
    deleteWorkControl(input: $input, condition: $condition) {
      id
      date
      windFarm
      tower
      startTime
      endTimePrev
      technicians
      workName
      safetyPriority
      endTime
      author
      maker
      createdAt
      updatedAt
    }
  }
`;
export const createWindFarmInformation = /* GraphQL */ `
  mutation CreateWindFarmInformation(
    $input: CreateWindFarmInformationInput!
    $condition: ModelWindFarmInformationConditionInput
  ) {
    createWindFarmInformation(input: $input, condition: $condition) {
      id
      prefecture
      name
      reading
      company
      fullName
      whereabouts
      manufacturer
      radix
      totalOutput
      longitude
      latitude
      latlong
      createdAt
      updatedAt
    }
  }
`;
export const updateWindFarmInformation = /* GraphQL */ `
  mutation UpdateWindFarmInformation(
    $input: UpdateWindFarmInformationInput!
    $condition: ModelWindFarmInformationConditionInput
  ) {
    updateWindFarmInformation(input: $input, condition: $condition) {
      id
      prefecture
      name
      reading
      company
      fullName
      whereabouts
      manufacturer
      radix
      totalOutput
      longitude
      latitude
      latlong
      createdAt
      updatedAt
    }
  }
`;
export const deleteWindFarmInformation = /* GraphQL */ `
  mutation DeleteWindFarmInformation(
    $input: DeleteWindFarmInformationInput!
    $condition: ModelWindFarmInformationConditionInput
  ) {
    deleteWindFarmInformation(input: $input, condition: $condition) {
      id
      prefecture
      name
      reading
      company
      fullName
      whereabouts
      manufacturer
      radix
      totalOutput
      longitude
      latitude
      latlong
      createdAt
      updatedAt
    }
  }
`;
export const createWindTurbineDetails = /* GraphQL */ `
  mutation CreateWindTurbineDetails(
    $input: CreateWindTurbineDetailsInput!
    $condition: ModelWindTurbineDetailsConditionInput
  ) {
    createWindTurbineDetails(input: $input, condition: $condition) {
      id
      date
      status
      windFarm
      tower
      description
      author
      obs
      createdAt
      updatedAt
    }
  }
`;
export const updateWindTurbineDetails = /* GraphQL */ `
  mutation UpdateWindTurbineDetails(
    $input: UpdateWindTurbineDetailsInput!
    $condition: ModelWindTurbineDetailsConditionInput
  ) {
    updateWindTurbineDetails(input: $input, condition: $condition) {
      id
      date
      status
      windFarm
      tower
      description
      author
      obs
      createdAt
      updatedAt
    }
  }
`;
export const deleteWindTurbineDetails = /* GraphQL */ `
  mutation DeleteWindTurbineDetails(
    $input: DeleteWindTurbineDetailsInput!
    $condition: ModelWindTurbineDetailsConditionInput
  ) {
    deleteWindTurbineDetails(input: $input, condition: $condition) {
      id
      date
      status
      windFarm
      tower
      description
      author
      obs
      createdAt
      updatedAt
    }
  }
`;
export const createWindTurbineNotePad = /* GraphQL */ `
  mutation CreateWindTurbineNotePad(
    $input: CreateWindTurbineNotePadInput!
    $condition: ModelWindTurbineNotePadConditionInput
  ) {
    createWindTurbineNotePad(input: $input, condition: $condition) {
      id
      date
      windFarm
      tower
      author
      obs
      createdAt
      updatedAt
    }
  }
`;
export const updateWindTurbineNotePad = /* GraphQL */ `
  mutation UpdateWindTurbineNotePad(
    $input: UpdateWindTurbineNotePadInput!
    $condition: ModelWindTurbineNotePadConditionInput
  ) {
    updateWindTurbineNotePad(input: $input, condition: $condition) {
      id
      date
      windFarm
      tower
      author
      obs
      createdAt
      updatedAt
    }
  }
`;
export const deleteWindTurbineNotePad = /* GraphQL */ `
  mutation DeleteWindTurbineNotePad(
    $input: DeleteWindTurbineNotePadInput!
    $condition: ModelWindTurbineNotePadConditionInput
  ) {
    deleteWindTurbineNotePad(input: $input, condition: $condition) {
      id
      date
      windFarm
      tower
      author
      obs
      createdAt
      updatedAt
    }
  }
`;
