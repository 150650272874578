import React from "react";

import * as moment from 'moment';
import 'moment/locale/ja';

import { Marker, InfoWindow } from "react-google-maps";

class WorkMarker extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.toggleInfoWindow = this.toggleInfoWindow.bind(this);
  }

  toggleInfoWindow() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  render() {
    return (
      <>
        <Marker key={this.props.i} onClick={this.toggleInfoWindow}
          position={{ lat: this.props.lat, lng: this.props.long }}>
          {this.state.isOpen &&
            <InfoWindow onCloseClick={this.toggleInfoWindow}>
              <div>
                <span>{this.props.work.windFarm} #{this.props.work.tower}</span><br />
                <span>作業員: {this.props.work.technicians}</span><br />
                <span>作業名: {this.props.work.workName}</span><br />
                <span>入構時間: {moment(this.props.work.startTime).format('LT')}</span><br />
                <span>出構予定時間: {moment(this.props.work.endTimePrev).format('LT')}</span>
              </div>
            </InfoWindow>
          }
        </Marker>
      </>
    );
  }
}

export default WorkMarker;
