import React from "react";
import EditAlarmData from "src/views/alarms/EditAlarmData";
import { Connect } from "aws-amplify-react";
import { graphqlOperation } from "aws-amplify";
import * as mutations from "src/graphql/mutations";

// reactstrap components
import AlarmCard from "src/components/AlarmCard";
import Badge from "src/components/AlarmBadge";
import Alert from "src/components/Alert";
import { Table, ColHeader, TableBody, Col } from "src/components/Table";
import { TableHead } from "@material-ui/core";

/**
 * AlarmTamanouraView
 *
 * @author Thais Watanabe
 */
const AlarmTamanouraView = (props) => {
  const { alarms } = props;

  // AlertView
  const AlertView = ({ order }) => (
    <Alert
      type={
        order.includes("禁止")
          ? "danger"
          : order.includes("遠隔")
          ? "info"
          : "success"
      }
      order={order}
    />
  );

  return (
    <>
      {alarms.map((alarm, i) => (
        <AlarmCard
          key={i}
          title={alarm.id + "-" + alarm.name}
          badge={
            <Badge color={alarm.reset === "手動" ? "warning" : "info"}>
              {alarm.reset}
            </Badge>
          }
        >
          {/* <CardBody> */}
          {alarm.order != null ? <AlertView order={alarm.order} /> : ""}
          {alarm.interaction != null
            ? alarm.interaction.split("\n").map((item, i) => (
                <div key={i} className="mt-2 flex items-center justify-between">
                  <p className="text-sm text-gray-500">{item}</p>
                  <Connect
                    mutation={graphqlOperation(mutations.updateAlarmTamanoura)}
                  >
                    {({ mutation }) => (
                      <EditAlarmData
                        onUpdate={mutation}
                        windfarm={"玉之浦"}
                        alarm={alarm}
                      />
                    )}
                  </Connect>
                </div>
              ))
            : "対応ありません"}
        </AlarmCard>
      ))}

      <Table>
        <TableHead>
          <tr>
            <ColHeader colSpan="9">検索詳細</ColHeader>
          </tr>
          <tr>
            <ColHeader>ID</ColHeader>
            <ColHeader>アラーム名</ColHeader>
            <ColHeader>リセット</ColHeader>
            <ColHeader>繰り返し回数</ColHeader>
            <ColHeader>検出センサ</ColHeader>
            <ColHeader>備考および原因</ColHeader>
            <ColHeader>検出条件</ColHeader>
            <ColHeader>対応</ColHeader>
            <ColHeader>リセット2</ColHeader>
          </tr>
        </TableHead>
        <TableBody>
          {alarms.map((alarm, i) => (
            <tr key={i}>
              <Col>{alarm.id}</Col>
              <Col>{alarm.name}</Col>
              <Col>
                <Badge color={alarm.reset === "手動" ? "warning" : "info"}>
                  {alarm.reset}
                </Badge>
              </Col>
              <Col>{alarm.repeatingCount}</Col>
              <Col>{alarm.detectionSensor}</Col>
              <Col>{alarm.cause}</Col>
              <Col>{alarm.detectionConditions}</Col>
              <Col>{alarm.interaction}</Col>
              <Col>{alarm.order}</Col>
            </tr>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default AlarmTamanouraView;
