import React from "react";
import EditAlarmData from "src/views/alarms/EditAlarmData";
import { Connect } from "aws-amplify-react";
import { graphqlOperation } from "aws-amplify";
import * as mutations from "src/graphql/mutations";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Table,
  Alert,
  Badge,
} from "reactstrap";

/**
 * AlarmReimeiView
 *
 * @author Thais Watanabe
 */
const AlarmReimeiView = (props) => {
  const { alarms } = props;

  // AlertView
  const AlertView = ({ order }) => (
    <Alert
      className="alert-with-icon"
      color={
        order.includes("禁止")
          ? "danger"
          : order.includes("遠隔")
          ? "info"
          : "success"
      }
    >
      <span className="tim-icons icon-alert-circle-exc" data-notify="icon" />
      <span data-notify="message">{order}</span>
    </Alert>
  );
  return (
    <>
      <Row>
        {alarms.map((alarm, i) => (
          <Col lg={Math.round(12 / alarms.length)} key={i}>
            <Card className="card">
              <CardHeader>
                <Row className="align-items-center">
                  <Col className="text-left">
                    <CardTitle tag="h4">
                      <Badge color={alarm.reset === "MR" ? "warning" : "info"}>
                        {alarm.reset}
                      </Badge>{" "}
                      {alarm.id} - {alarm.name}
                    </CardTitle>
                  </Col>
                  <Col>
                    <Connect
                      mutation={graphqlOperation(mutations.updateAlarmReimei)}
                    >
                      {({ mutation }) => (
                        <EditAlarmData
                          onUpdate={mutation}
                          windfarm={"れいめい"}
                          alarm={alarm}
                        />
                      )}
                    </Connect>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {alarm.order != null ? <AlertView order={alarm.order} /> : ""}

                <h4>
                  {alarm.interaction2 != null
                    ? alarm.interaction2
                        .split("\n")
                        .map((item, i) => <p key={i}>{item}</p>)
                    : "対応ありません"}
                </h4>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>

      <Row>
        <Col xs="12">
          <Card className="card">
            <CardHeader>
              <Row>
                <Col className="text-left">
                  <CardTitle tag="h4">検索詳細</CardTitle>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Table className="tablesorter w-auto" responsive>
                <thead className="text-primary">
                  <tr>
                    <th>ID</th>
                    <th style={{ width: "8%" }}>アラーム名</th>
                    <th style={{ width: "8%" }}>レベル</th>
                    <th style={{ width: "8%" }}>故障ランプ</th>
                    <th style={{ width: "8%" }}>リセット</th>
                    <th style={{ width: "20%" }}>説明</th>
                    <th style={{ width: "20%" }}>対応①</th>
                    <th style={{ width: "20%" }}>対応②</th>
                    <th style={{ width: "7%" }}>リセット2</th>
                  </tr>
                </thead>
                <tbody>
                  {alarms.map((alarm, i) => (
                    <tr key={i}>
                      <td>{alarm.id}</td>
                      <td>{alarm.name}</td>
                      <td>{alarm.level}</td>
                      <td>{alarm.falha}</td>
                      <td>
                        <Badge
                          color={alarm.reset === "MR" ? "warning" : "info"}
                        >
                          {alarm.reset}
                        </Badge>
                      </td>
                      <td>{alarm.description}</td>
                      <td>{alarm.interaction1}</td>
                      <td>{alarm.interaction2}</td>
                      <td>{alarm.order}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AlarmReimeiView;
