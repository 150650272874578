import React, { useState } from "react";

import gpv from "src/assets/img/gpv-legend.png";

/**
 * Dashboard
 *
 * @author Thais Watanabe
 */
const Dashboard = () => {
  const [activeTab, setActiveTab] = useState("Windy");

  const tabs = [
    { name: "Windy" },
    { name: "SCW" },
    { name: "雷予報" },
    { name: "台風情報" },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <div className="mx-auto py-6 sm:px-6 lg:px-8">
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          タブを選択
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
          defaultValue={tabs.find((tab) => tab.current)}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav
          className="relative z-0 rounded-lg shadow flex divide-x divide-gray-200"
          aria-label="Tabs"
        >
          {tabs.map((tab, tabIdx) => (
            <button
              key={tab.name}
              onClick={() => setActiveTab(tab.name)}
              className={classNames(
                activeTab === tab.name
                  ? "text-gray-900"
                  : "text-gray-500 hover:text-gray-700",
                tabIdx === 0 ? "rounded-l-lg" : "",
                tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
                "group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
              )}
              aria-current={activeTab === tab.name ? "page" : undefined}
            >
              <span>{tab.name}</span>
              <span
                aria-hidden="true"
                className={classNames(
                  activeTab === tab.name ? "bg-indigo-500" : "bg-transparent",
                  "absolute inset-x-0 bottom-0 h-0.5"
                )}
              />
            </button>
          ))}
        </nav>
      </div>
      <div className="h-screen">
        {activeTab === "Windy" && (
          <iframe
            width="100%"
            height="100%"
            src="maps.html"
            title="Windy"
            frameBorder="0"
          ></iframe>
        )}
        {activeTab === "SCW" && (
          <div className="flex h-screen">
            <iframe
              width="100%"
              height="100%"
              src="https://supercweather.com/?lat=32.611616&lng=129.743042&model=msm&element=wa"
              title="SCW"
              frameBorder="0"
            ></iframe>

            <img src={gpv} alt="GPV legend" />
          </div>
        )}
        {activeTab === "雷予報" && (
          <iframe
            width="100%"
            height="100%"
            src="https://www.jma.go.jp/bosai/nowc/#zoom:5/lat:36.756490/lon:135.043945/colordepth:normal/elements:thns&liden"
            title="雷予報"
            frameBorder="0"
          ></iframe>
        )}
        {activeTab === "台風情報" && (
          <iframe
            width="100%"
            height="100%"
            src="https://www.jma.go.jp/bosai/map.html#4/31.2/139.615/&5/&elem=root&typhoon=all&contents=typhoon"
            title="台風情報"
            frameBorder="0"
          ></iframe>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
