import { Fragment, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { Route, Switch, NavLink } from "react-router-dom";
import { Auth } from "aws-amplify";
import { AmplifySignOut } from "@aws-amplify/ui-react";
import { Toaster } from "react-hot-toast";

import routes from "src/routes.js";
import logo from "src/assets/svg/jks-logo.svg";

/**
 * Layout
 *
 * @author Thais Watanabe
 */
const Layout = (props) => {
  const [loggedUser, setLoggedUser] = useState("");

  // Get current user
  Auth.currentAuthenticatedUser()
    .then((result) => {
      setLoggedUser(result.username);
    })
    .catch((error) => console.log(error));

  /**
   * getRoutes
   * @param {*} routes
   * @returns
   */
  const getRoutes = (routes) => {
    return routes.map((prop, key) => (
      <Route
        key={key}
        path={prop.path}
        component={prop.component}
        exact={prop.exact}
      />
    ));
  };

  // /**
  //  * activeRoute
  //  * @param {*} routeName
  //  * @returns
  //  */
  // function getTitleByPathname() {
  //   const found = routes.filter((obj) => {
  //     return obj.path === props.location.pathname;
  //   });
  //   return found[0].name;
  // }

  return (
    <div>
      <Toaster position="top-right" reverseOrder={false} />
      <Disclosure as="nav" className="bg-gray-800">
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex items-center justify-between h-16">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <img
                      className="h-8 w-8"
                      src={logo}
                      alt="Workflow"
                    />
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-10 flex items-baseline space-x-4">
                      {routes.map(
                        (item, itemIdx) =>
                          item.visible && (
                            <NavLink
                              key={itemIdx}
                              to={item.path}
                              exact={item.exact}
                              activeClassName="bg-gray-900 text-white"
                              className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-base"
                            >
                              {item.name}
                            </NavLink>
                          )
                      )}
                    </div>
                  </div>
                </div>
                <div className="hidden md:block">
                  <div className="ml-4 flex items-center md:ml-6">
                    {/* Profile dropdown */}
                    <Menu as="div" className="ml-3 relative">
                      {({ open }) => (
                        <>
                          <div>
                            <Menu.Button className="max-w-xs bg-gray-800 rounded-full flex items-center text-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                              <span className="sr-only">Open user menu</span>
                              <div className="bg-white h-10 w-10 rounded-full flex">
                                <p className="text-center text-gray-800 text-2xl m-auto">
                                  {loggedUser.length > 0 &&
                                    loggedUser.charAt(0).toUpperCase()}
                                </p>
                              </div>
                            </Menu.Button>
                          </div>
                          <Transition
                            show={open}
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items
                              static
                              className="z-50 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                            >
                              <AmplifySignOut buttonText="ログアウト" />
                            </Menu.Items>
                          </Transition>
                        </>
                      )}
                    </Menu>
                  </div>
                </div>
                <div className="-mr-2 flex md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="md:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                {routes.map(
                  (item, itemIdx) =>
                    item.visible && (
                      <NavLink
                        key={itemIdx}
                        to={item.path}
                        activeClassName="bg-gray-900 text-white"
                        className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                      >
                        {item.name}
                      </NavLink>
                    )
                  // )
                )}
              </div>
              <div className="pt-4 pb-3 border-t border-gray-700">
                <div className="flex items-center px-5">
                  <div className="bg-white h-10 w-10 rounded-full flex">
                    <p className="text-center text-gray-800 text-2xl m-auto">
                      {loggedUser.length > 0 &&
                        loggedUser.charAt(0).toUpperCase()}
                    </p>
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium text-white">
                      {loggedUser}
                    </div>
                  </div>
                </div>
                <div className="mt-3 px-2 space-y-1">
                  <AmplifySignOut buttonText="ログアウト" />
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      {/* <header className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
          <h1 className="text-lg leading-6 font-semibold text-gray-900">
            {getTitleByPathname()}
          </h1>
        </div>
      </header> */}
      <main className="bg-gray-100 h-full w-full">
        {/* <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          <div className="px-4 py-4 sm:px-0">
          </div>          
        </div> */}
        <Switch>{getRoutes(routes)}</Switch>
      </main>
      <footer>
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
          <div className="py-8 text-sm text-gray-500 text-center sm:text-left">
            <span className="block sm:inline">
              &copy; 2021 有限会社イー・ウィンド
            </span>
            <span className="block sm:inline">All rights reserved.</span>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Layout;
